import React, { useState } from "react";
import cn from "classnames";
import styles from "./Account.module.sass";
import Item from "./Item";
import Icon from "../../../../components/Icon";
import TextInput from "../../../../components/TextInput";
import Editor from "../../../../components/Editor";

const Account = ({ className }) => {
  const [content, setContent] = useState();
  // BACKGROUN UPLOAD PIC
  const [selectedImage, setSelectedImage] = useState(
    "/images/content/bg-shop.jpg"
  );
  // ProfilePic UPLOAD PIC
  const [selectedImage2, setSelectedImage2] = useState(
    "/images/content/avatar.jpg"
  );
  // BACKGROUN UPLOAD PIC
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const newImageUrl = e.target.result;
        setSelectedImage(newImageUrl);
      };

      reader.readAsDataURL(file);
    }
  };
  // ProfilePic UPLOAD PIC
  const handleFileChange2 = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const newImageUrl = e.target.result;
        setSelectedImage2(newImageUrl);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <div className={styles.account}>
      <div className={styles.accountChildreen}>
        <Item
          className={cn(styles.card, className)}
          title="Edit"
          classTitle="title-purple"
        >
          <div className={styles.background}>
            <div className={styles.backgroundPic}>
              <img src={selectedImage} alt="Avatar" className={styles.bgimg} />
            </div>

            <div className={styles.file1}>
              <input type="file" onChange={handleFileChange} />
              <button className={cn("button", styles.button)} type="button">
                <Icon name="add" size="24" />
                <span>Upload New picture</span>
              </button>
            </div>
          </div>

          <div className={styles.profile}>
            <div className={styles.avatar}>
              <img src={selectedImage2} alt="Avatar" />
            </div>
            <div className={styles.file}>
              <input type="file" onChange={handleFileChange2} />
              <button className={cn("button", styles.button)} type="button">
                <Icon name="add" size="24" />
                <span>Upload New picture</span>
              </button>
            </div>
          </div>
          <div className={styles.fieldset}>
            <TextInput
              className={styles.field}
              label="Name"
              name="display-name"
              type="text"
              required
            />

            <label className={styles.label}>Sector</label>
            <select
              id="Sector"
              className={styles.field}
              name="display-name"
              required
            >
              <option value="" label="Purchase Now"></option>
              <option value="option1" label="Purchase Now 1"></option>
              <option value="option2" label="Purchase Now 2"></option>
            </select>
            <label className={styles.label}>Teams</label>
            <select
              id="teams"
              className={styles.field}
              name="display-name"
              required
            >
              <option value="" label="A"></option>
              <option value="option1" label="B"></option>
              <option value="option2" label="c"></option>
            </select>
            <TextInput
              className={styles.field}
              label="Company "
              name="display-name"
              type="text"
              required
            />
            <TextInput
              className={styles.field}
              label=" Expertise"
              name="display-name"
              type="text"
              required
            />
            <Editor
              state={content}
              onChange={setContent}
              classEditor={styles.editor}
              label="Bio"
              tooltip="Description"
            />
          </div>
        </Item>
      </div>
      <button className={cn("button", styles.button)} style={{ marginTop: '2rem' ,marginBottom: '2rem' }}>Edit</button>
    </div>
  );
};

export default Account;
