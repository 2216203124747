import React, { useEffect, useState } from "react";
import styles from "./ListAccount.module.sass";
import cn from "classnames";
import Card from "../../../../components/Card";
import Form from "../../../../components/Form";
// import Dropdown from "../../components/Dropdown";
import Market from "./Market";
import Table from "./Table";
import Popup from "reactjs-popup";
import TextInput from "../../../../components/TextInput";
// data
import { traffic } from "../../../../mocks/traffic";
import { viewers } from "../../../../mocks/viewers";
import { market } from "../../../../mocks/market";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import DivCard from "../../../../components/CardDiv"
import swal from "sweetalert";

const indicatorsTraffic = [
  {
    title: "Market",
    color: "#FFBC99",
  },
  {
    title: "Social media",
    color: "#CABDFF",
  },
  {
    title: "Direct",
    color: "#B5E4CA",
  },
  {
    title: "UI8",
    color: "#B1E5FC",
  },
  {
    title: "Others",
    color: "#FFD88D",
  },
];

const indicatorsViewers = [
  {
    title: "Followers",
    color: "#B5E4CA",
  },
  {
    title: "Others",
    color: "#CABDFF",
  },
];

const ListAccount = () => {
  const navigation = ["Viewers"];

  const [activeTab, setActiveTab] = useState(navigation[0]);
  const [search, setSearch] = useState("");
  const {id} = useParams();
  const [users, setUsers] = useState([]);
  const [title, setTitle] = useState([]);
    const [loading, setloading] = useState(true);
    useEffect(()=>{
      axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.get(`api/GetCompanyUsers/${id}`).then(res=>{
        if(res.status === 200){
          setUsers(res.data.data);
          setTitle(res.data.title);
        }
        setloading(false)
      
      });                         
    });
  },[])

  const userid = localStorage.getItem("id");
  const username = localStorage.getItem("name");
  const [registerInput, setRegister] = useState({
    num_users: "",
    num_cards: "",
    earnings: "",
    reference: "",
    error_list: [],
    sucess: "",
  });

  const handleInput = (e) => {
    // e.presist();
    setRegister({ ...registerInput, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create FormData object
    const formData = new FormData();
    // formData.append("name", registerInput.name);
    // formData.append("email", registerInput.email);
    formData.append("seller_name", username);
    formData.append("num_users", registerInput.num_users);
    formData.append("num_cards", registerInput.num_cards);
    formData.append("earnings", registerInput.earnings);
    formData.append("reference", registerInput.reference);
    formData.append("user_id", userid);

    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios
        .post(`api/topup/${id}`, formData, {
          withCredentials: true, // Include cookies with the request
          headers: {
            "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
            "X-Requested-With": "XMLHttpRequest",
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            setRegister({
              ...registerInput,
              sucess: res.data.message,
              error_list: [],
            });
            swal("Success", res.data.message, "success");
            // history(`/list-account/${id}`);
            window.location.reload()
          } else {
            setRegister({
              ...registerInput,
              error_list: res.data.validation_errors,
            });
          }
        })
        .catch((error) => {
          console.error("Error registering:", error);
          // Handle registration error
        });
    });
  };
  
  // console.log(title)
  return (
    <DivCard
      className={styles.card}
      title={title}
      classTitle={cn("title-purple", styles.title)}
      classCardHead={styles.head}
      head={
        <>
          <Form
            className={styles.form}
            value={search}
            setValue={setSearch}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search user , sector ..."
            type="text"
            name="search"
            icon="search"
          />
          <div className={styles.contacts}>
            <Link to={`/sales/details/${id}`} className={cn("button", styles.button)}>
              Cards
            </Link>
            <Popup
              trigger={
                <button className={cn("button", styles.button)}>Top up</button>
              }
              modal
              nested
            >
              {(close) => (
                <div className="modal">
                  <button className={styles.close} onClick={close}>
                    &times;
                  </button>
                  <div className="content">
                    {" "}
                    <Card
                     onSubmit={handleSubmit}
                      className={styles.card}
                      title="Top Up"
                      classTitle={cn("title-purple", styles.title)}
                      classCardHead={styles.head}
                    >
                      <div className={styles.inputrow}>
                        <TextInput
                          className={`${styles.field} ${styles.rowfield}`}
                          label="Number of cards "
                          name="num_cards"
                          type="number"
                          required
                          onChange={handleInput}
                          value={registerInput.num_cards}
                        />
                        <TextInput
                          className={`${styles.field} ${styles.rowfield}`}
                          label="Number of User"
                          name="num_users"
                          type="number"
                          required
                          onChange={handleInput}
                          value={registerInput.num_users}
                        />
                      </div>
                      <div className={`${styles.inputrow} ${styles.rowmargin}`}>
                        <TextInput
                          className={`${styles.field} ${styles.rowfield}`}
                          label="Cost"
                          name="earnings"
                          type="number"
                          required
                          onChange={handleInput}
                          value={registerInput.earnings}
                        />
                        <TextInput
                          className={`${styles.field} ${styles.rowfield}`}
                          label="Transaction reference"
                          name="reference"
                          type="text"
                          required
                          onChange={handleInput}
                          value={registerInput.reference}
                        />
                      </div>
                      <button
                        className={cn(
                          "button",
                          styles.button,
                          styles.buttonright
                        )}
                      >
                        Top Up
                      </button>
                    </Card>
                  </div>
                </div>
              )}
            </Popup>
          </div>
        </>
      }
    >
      <div className={styles.products}>
        <div className={styles.wrapper}>
          {activeTab === navigation[0] && <Market items={users} search={search} loading={loading}/>}
          {activeTab === navigation[1] && (
            <Table
              title="Traffic source"
              items={traffic}
              legend={indicatorsTraffic}
            />
          )}
          {activeTab === navigation[2] && (
            <Table title="Viewers" items={viewers} legend={indicatorsViewers} />
          )}
        </div>
      </div>
    </DivCard>
  );
};

export default ListAccount;
