import React, { useState } from "react";
import {  Link, useNavigate } from "react-router-dom";
import styles from "./Control.module.sass";
import styless from "./Control.module.css";
import cn from "classnames";
import Actions from "../../../../../components/Actions";
import Popup from "reactjs-popup";
import swal from "sweetalert";
import Swal from "sweetalert2";
import axios from "axios";
import Icon from "../../../../../components/Icon";

const Control = ({ className, visibleActions, setVisibleActions, up,item,cards }) => {

  const deleteAccount = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will Delete this Account!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#8E59FF",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel !",
      closeOnConfirm: false,
      closeOnCancel: false,
    }).then((result) => {
      if (result.isConfirmed) {
        e.preventDefault();
        // If user confirms deletion, proceed with deletion
        axios.get("/sanctum/csrf-cookie").then((response) => {
          axios.post(`api/ArchiveUser/${item.id}`).then((res) => {
            if (res.data.status === 200) {
              // Display success message
              Swal.fire("Deleted!", "Account has been deleted.", "success");
              window.location.reload();
            }
          });
        });
      } else {
        // If user cancels, show cancellation message
        Swal.fire("Cancelled", "Your Account is safe :)", "error");
      }
    });
  };

  const history = useNavigate();
  const [registerInput, setRegister] = useState({
    description: "",
    error_list: [],
    sucess: "",
  });
  const handleInput = (e) => {
    // e.presist();
    setRegister({ ...registerInput, [e.target.name]: e.target.value });
  };

  
  const UnBlockUser = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will UnBlock this User!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#8E59FF",
      confirmButtonText: "Yes, UnBlock it!",
      cancelButtonText: "No, cancel !",
      closeOnConfirm: false,
      closeOnCancel: false,
    }).then((result) => {
      if (result.isConfirmed) {
        e.preventDefault();
        // If user confirms deletion, proceed with deletion
        axios.get("/sanctum/csrf-cookie").then((response) => {
          axios.post(`api/UnBlockUser/${item.id}`).then((res) => {
            if (res.data.status === 200) {
              // Display success message
              Swal.fire("UnBlock!", "User has been Active.", "success");
              window.location.reload();
            }
          });
        });
      } else {
        // If user cancels, show cancellation message
        Swal.fire("Cancelled", "Your User is Still Blocked :)", "error");
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create FormData object
    const formData = new FormData();
    formData.append("description", registerInput.description);

    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios
        .post(`api/BlockUser/${item.id}`, formData, {
          withCredentials: true, // Include cookies with the request
          headers: {
            "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
            "X-Requested-With": "XMLHttpRequest",
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            setRegister({
              ...registerInput,
              sucess: res.data.message,
              error_list: [],
            });
            swal("Success", res.data.message, "success");
            // history("/company");
            window.location.reload();
          } else if (res.data.message === 422) {
            swal("Error", res.data.validation_errors, "error");
          } else {
            setRegister({
              ...registerInput,
              error_list: res.data.validation_errors,
            });
          }
        })
        .catch((error) => {
          console.error("Error registering:", error);
          // Handle registration error
        });
    });
  };
  // DURATION INPUT
  const [duration, setDuration] = useState("");

  const handleDurationChange = (e) => {
    const inputDuration = e.target.value;
    setDuration(inputDuration);
  };
  // POP UP Block
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };
  // POP UP Delete
  const open2Modal = () => {
    setOpen2(true);
  };

  const close2Modal = () => {
    setOpen2(false);
  };
  const open3Modal = () => {
    setOpen3(true);
  };

  const close3Modal = () => {
    setOpen3(false);
  };
  // ACTIONS
  const actions = [

    {
      title: "Block",
      icon: "edit",
      action: openModal,
    },

    {
      title: "Delete",
      icon: "trash",
      action: open2Modal,
    },
  ];
  const actions2 = [
    // {
    //   title: "Edit product",
    //   icon: "edit",
    //   action: () => {},
    // },
    // {
    //   title: "View comment",
    //   icon: "message",
    //   action: () => console.log("View comment"),
    // },

    {
      title: "UnBlock",
      icon: "message",
      action: open3Modal,
    },
  ];

  return (
    <div className={cn(styles.control, className)}>
    <Link to={`/company/EditCompanyUser/${item.id}`} className={styles.button}>
        <Icon name="edit" size="20" />
      </Link>

    {
      (item.profile.status === 'active'||item.profile.status === 'deactive') ?  
      <Actions
        className={styles.actions}
        classActionsHead={styles.actionsHead}
        classActionsBody={styles.actionsBody}
        classActionsOption={styles.actionsOption}
        items={actions}
        visible={visibleActions}
        setVisible={setVisibleActions}
        up={up}
      />:
      <Actions
      className={styles.actions}
      classActionsHead={styles.actionsHead}
      classActionsBody={styles.actionsBody}
      classActionsOption={styles.actionsOption}
      items={actions2}
      visible={visibleActions}
      setVisible={setVisibleActions}
      up={up}
    />
     }

     {(item.profile.status === 'active'||item.profile.status === 'deactive') ? (
      <Popup open={open} closeOnDocumentClick onClose={closeModal}>
        <div className={styless.overlay}></div>
        <div className={styless.modal}>
          <p className={styless.close} onClick={closeModal}>
            &times;
          </p>

          <div className={styless.header}>Block</div>
          <div className={styless.content}>{item.profile.name}</div>
          <div className={styless.footer}>Reason</div>
          <form onSubmit={handleSubmit}>
            <div className={styless.duration}>
              <input
                type="text"
                id="durationInput"
                name="description"
                onChange={handleInput}
                value={registerInput.description}
                required
              />
            </div>
            <button type="submit" className={styless.buttonstyles}>
              Block
            </button>
          </form>
        </div>
      </Popup>
      ) : (
        <Popup open={open3} closeOnDocumentClick onClose={close3Modal}>
          <div className={styless.overlay}></div>
          <div className={styless.modal}>
            <p className={styless.close} onClick={close3Modal}>
              &times;
            </p>

            <div className={styless.header}>UnBlock</div>
            <div className={styless.content}>{item.profile.name}</div>

            <button onClick={UnBlockUser} className={styless.buttonstyles}>
              UnBlock
            </button>
          </div>
        </Popup>
      )}
      

      <Popup open={open2} closeOnDocumentClick onClose={close2Modal}>
        <div className={styless.overlay}></div>
        <div className={styless.modal}>
          <p className={styless.close} onClick={close2Modal}>
            &times;
          </p>

          <div className={styless.header}>Delete</div>
          <div className={styless.content}>{item.profile.name}</div>

          <button onClick={deleteAccount} className={styless.buttonstyles}>
            Delete
          </button>
        </div>
      </Popup>
    </div>
  );
};

export default Control;
