import React from "react";
import cn from "classnames";
import styles from "./Profile.module.sass";
import Icon from "../../../../../components/Icon";
import { Link } from "react-router-dom";
import Popup from 'reactjs-popup';
import { Switch } from "antd";

const Profile = ({ Links }) => {
  return (
    <div>
      {Links.map((x, index) => (
        <div className={styles.profile}>
          <div className={styles.details}>
            <div className={styles.avatar}>
              {x.image === null ?
                <img src="/images/content/avatar-1.jpg" alt="Avatar" />
                :
                <img src={`https://daleelnfc.com/storage/${x.image}`} alt="Avatar" />
              }
            </div>
            <div className={styles.wrap}>
              <div className={cn("h4", styles.man)}>{x.name ?? 'x'}</div>
              <div className={styles.info}>{x.sector ?? 'x'}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default Profile;