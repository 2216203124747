import React, { useState } from "react";
import styles from "./Row.module.sass";

import ModalProduct from "../../../../../components/ModalProduct";

const Row = ({ up }) => {
  const [visibleActions, setVisibleActions] = useState(false);
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);

  return (
    <>
      <div className={styles.row} onMouseLeave={() => setVisibleActions(false)}>
        <div className={styles.col}>
          <div className={styles.user}>
            <div className={styles.avatar}>
              <img src="/images/content/avatar-1.jpg" alt="Avatar" />
            </div>
            <div className={styles.details}>
              <div className={styles.man}>Chelsie Haley</div>
              <div className={styles.login}>IT</div>
            </div>
          </div>
          <div className={styles.details}></div>
        </div>
      

        <div className={styles.col}>mennamo.com</div>

        <div className={styles.col}>789-654</div>
        <div className={styles.col}>Khalil Kareem</div>
        <div className={styles.col}>x</div>
      </div>
      <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
    </>
  );
};

export default Row;
