import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./Control.module.sass";
import styless from "./Control.module.css";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import Actions from "../../../../components/Actions";
import Popup from "reactjs-popup";

const Control = ({ className, visibleActions, setVisibleActions, up }) => {

  // DURATION INPUT
  const [duration, setDuration] = useState("");

  const handleDurationChange = (e) => {
    const inputDuration = e.target.value;
    setDuration(inputDuration);
  };
  // POP UP Block
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };
  // POP UP Delete
  const open2Modal = () => {
    setOpen2(true);
  };

  const close2Modal = () => {
    setOpen2(false);
  };
  // ACTIONS
  const actions = [

  
    {
      title: "Block",
      icon: "edit",
      action: openModal,
    },

    {
      title: "Delete",
      icon: "trash",
      action: open2Modal,
    },
  ];

  return (
    <div className={cn(styles.control, className)}>
    <Link to="/account" className={styles.button}>
        <Icon name="edit" size="20" />
    </Link>

      <Actions
        className={styles.actions}
        classActionsHead={styles.actionsHead}
        classActionsBody={styles.actionsBody}
        classActionsOption={styles.actionsOption}
        items={actions}
        visible={visibleActions}
        setVisible={setVisibleActions}
        up={up}
      />

      <Popup open={open} closeOnDocumentClick onClose={closeModal}>
        <div className={styless.overlay}></div>
        <div className={styless.modal}>
          <p className={styless.close} onClick={closeModal}>
            &times;
          </p>

          <div className={styless.header}>Block</div>
          <div className={styless.content}>Chelsie Haley</div>
          <div className={styless.footer}>Duration</div>
          <div className={styless.duration}>
            <input
              type="text"
              id="durationInput"
              value={duration}
              onChange={handleDurationChange}
            />
          </div>
          <button className={styless.buttonstyles}>Block</button>
        </div>
      </Popup>

      <Popup open={open2} closeOnDocumentClick onClose={close2Modal}>
        <div className={styless.overlay}></div>
        <div className={styless.modal}>
          <p className={styless.close} onClick={close2Modal}>
            &times;
          </p>

          <div className={styless.header}>Delete</div>
          <div className={styless.content}>Chelsie Haley</div>

          <button className={styless.buttonstyles}>Delete</button>
        </div>
      </Popup>
    </div>
  );
};

export default Control;
