import React from "react";
import cn from "classnames";
import styles from "./Profile.module.sass";
import Icon from "../../../../components/Icon";
import { Link } from "react-router-dom";

const Profile = ({ Links }) => {
  return (
    <div>
      {Links.map((x, index) => (
        <div className={styles.profile}>
          <div className={styles.details}>
            <div className={styles.avatar}>
              {x.image === null ? <img src="/images/content/avatar-1.jpg" alt="Avatar" /> : <img
                src={`http://localhost:8000/storage/${x.image}`}
                alt="Avatar"
              />}

              {/* <button className={styles.add}>
                <Icon name="add" />
              </button> */}
            </div>
            <div className={styles.wrap}>
              <div className={cn("h4", styles.man)}>{x.name ?? 'x'}</div>
              <div className={styles.info}>{x.sector ?? 'x'}</div>
            </div>
          </div>
          {/* <div className={styles.contacts}>
            <Link
              to={`/sales/sales/shop/newlinks/${x.id}`}
              className={cn("button", styles.button)}
            >
              Add New Link
            </Link>
            
          </div> */}
        </div>
      ))}
    </div>
  );
};

export default Profile;
