import React, { useState } from "react";
import styles from "./Market.module.sass";
import Row from "./Row";
import Icon from "../../../../components/Icon";

const Market = ({ items }) => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5; // Number of items per page

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = currentPage * pageSize;
  const visibleItems = items.slice(startIndex, endIndex);

  const totalPages = Math.ceil(items.length / pageSize);

  const goToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <div className={styles.market}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>Name</div>
          <div className={styles.col}>Email</div>
          <div className={styles.col}>Phone Number</div>
          <div className={styles.col}>Companies</div>
        </div>
        {visibleItems.map((user) =>
         
              <Row
                item={user}
                key={user.id}
                value={selectedFilters.includes(user.id)}
                onChange={() => handleChange(user.id)}
              />
        )}
      </div>
      {totalPages > 1 && (
        <div className={styles.foot}>
          <button
            className={styles.arrow}
            onClick={goToPrevPage}
            disabled={currentPage === 1}
          >
            <Icon name="arrow-left" size="20" />
          </button>
          <button
            className={styles.arrow}
            onClick={goToNextPage}
            disabled={currentPage === totalPages}
          >
            <Icon name="arrow-right" size="20" />
          </button>
        </div>
      )}
    </div>
  );
};

export default Market;
