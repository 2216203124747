import React, { useState } from "react";
import styles from "./Row.module.sass";
import ModalProduct from "../../../../../components/ModalProduct";
import { Link } from "react-router-dom";


const Row = ({ item, value, onChange, up }) => {
  const [visibleActions, setVisibleActions] = useState(false);
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);

  return (
    <>
      <div className={styles.row} onMouseLeave={() => setVisibleActions(false)}>
        <div className={styles.col}>
          <div className={styles.user}>
            <div className={styles.avatar}>
            {
                item.image ? <img src={`https://daleelnfc.com/storage/${item.image}`} alt="Avatar" />
                  :
                  <img src="/images/logo-light.png" alt="Avatar" />
              }
            </div>

            <div className={styles.details}>
              <div className={styles.man}>
                <div className={styles.label}>Name</div>
                {item.name ?? 'x'}</div>
              <div className={styles.login}>
                <div className={styles.label}>Sector</div>
                {item.sector ?? 'x'}</div>
            </div>
          </div>
        </div>

        <div className={styles.col}>
          <div className={styles.label}>Email</div>
          {item.email}</div>

        <div className={styles.col}>
          <div className={styles.label}>Phone Number</div>
          {item.phone ?? 'x'}</div>
        <div className={styles.col}>
          <div className={styles.label}>Company</div>
          {item.company ?? 'x'}</div>
        <div className={styles.col}>
          <div className={styles.label}>From</div>
          {item.from ?? 'x'}</div>
        <div className={styles.col}>
          <Link to={`/Viewprofile/${item.url}`} className={styles.view}>View</Link>
        </div>
      </div>
      <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
    </>
  );
};

export default Row;
