import React, { useState } from "react";
import styles from "./Row.module.sass";

import ModalProduct from "../../../../../components/ModalProduct";

const Row = ({ up, item }) => {
  const [visibleActions, setVisibleActions] = useState(false);
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);

  return (
    <>
      <div className={styles.row} onMouseLeave={() => setVisibleActions(false)}>
        <div className={styles.col}>
          <div className={styles.user}>
            <div className={styles.avatar}>
              <img src={`https://daleelnfc.com/storage/${item.image}`} alt="Avatar" />
            </div>
            <div className={styles.details}>
              <div className={styles.man}>{item.profile.name ?? 'x'}</div>
              <div className={styles.login}>{item.profile.sector ?? 'x'}</div>
            </div>
          </div>
          <div className={styles.details}></div>
        </div>
        <div className={styles.col}>{item.email}</div>
        <div className={styles.col}>{item.phone ?? 'x'}</div>
        <div className={styles.col}>{item.profile.company ?? 'x'}</div>
      </div>

      <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
    </>
  );
};

export default Row;
