import React, { useState } from "react";
import styles from "./Row.module.sass";

import ModalProduct from "../../../../../components/ModalProduct";

const Row = ({ item, up }) => {
  const [visibleActions, setVisibleActions] = useState(false);
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);
  return (
    <>
      <div className={styles.row} onMouseLeave={() => setVisibleActions(false)}>
        <div className={styles.col}>
          <div className={styles.user}>
            <div className={styles.avatar}>
             { item.profile.image===null?
              <img src="/images/content/avatar-1.jpg" alt="Avatar" />
              :
              <img
                src={`https://daleelnfc.com/storage/${item.profile.image}`}
                alt="Avatar"
              />}
            </div>
            <div className={styles.details}>
              <div className={styles.man}>
                <div className={styles.label}>Name</div>
                {item.profile.name??'x'}
              </div>
              <div className={styles.login}>
                <div className={styles.label}>Sector</div>
                {item.profile.sector ?? "x"}
              </div>
            </div>
          </div>
          <div className={styles.details}></div>
        </div>

        <div className={styles.col}>
          <div className={styles.label}>Email</div>
          {item.email??'x'}
        </div>

        <div className={styles.col}>
          <div className={styles.label}>Phone Number</div>
          {item.phone ??  "x"}
        </div>
        <div className={styles.col}>
          <div className={styles.label}>Company</div>
          {item.profile.company ?? "x"}
        </div>
      </div>
      <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
    </>
  );
};

export default Row;
