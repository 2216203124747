import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import TooltipGlodal from "../../../../../components/TooltipGlodal";
import Card from "../../../../../components/Card";
import Icon from "../../../../../components/Icon";
import jsPDF from "jspdf";
import axios from "axios";

const items = [
  {
    title: "No of employee",
    icon: "user",
    background: "#A694F040",
  },
  {
    title: "Total Lead generate",
    icon: "products",
    background: "#A694F040",
  },
  {
    title: "Number Connections",
    icon: "products",
    background: "#A694F040",
  },
  {
    title: "No of share info",
    icon: "share",
    background: "#A694F040",
  },
  {
    title: "No card scanner",
    icon: "scan",
    background: "#A694F040",
  },
  {
    title: "Score",
    icon: "activity",
    background: "#A694F040",
  },
];

const Overview = ({ className }) => {
  const [itemsData, setItemsData] = useState([]);
  const cid = localStorage.getItem("company_id");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      await axios.get("/sanctum/csrf-cookie");
      const res = await axios.get(`api/countsCompanyDashboard?id=${cid}`);

      if (res.status === 200) {
        const data = res.data.data;
        const updatedItems = items.map((item) => {
          switch (item.title) {
            case "No of employee":
              return { ...item, counter: data.no_employee };
            case "Total Lead generate":
              return { ...item, counter: data.totalLead };
            case "Number Connections":
              return { ...item, counter: data.no_connections };
            case "No of share info":
              return { ...item, counter: data.no_shareinfo };
            case "No card scanner":
              return { ...item, counter: data.no_scanned };
            case "Score":
              return { ...item, counter: data.score };
            default:
              return item;
          }
        });
        setItemsData(updatedItems);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleExtract = (e) => {
    e.preventDefault(); // Prevent default form submission or button action
    const doc = new jsPDF();

    // Set the title for the PDF
    doc.setFontSize(20);
    doc.text("Overview Data", 15, 15);

    // Add items data to the PDF
    let yPos = 30;
    itemsData.forEach((item, index) => {
      doc.setFontSize(12);
      doc.text(`${item.title}: ${item.counter}`, 15, yPos + index * 10);
    });

    // Save the PDF
    doc.save("overview.pdf");
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title="Overview"
        classTitle="title-purple"
        head={
          <>
            <button
              className={cn("button", styles.button)}
              onClick={handleExtract}
            >
              Extract
            </button>
          </>
        }
      >
        <div className={`row${styles.overview}`}>
          <div className={styles.list}>
            {itemsData.map((x, index) => (
              <div
                className={`col-md-4 ${styles.item}`}
                key={index}
                style={{ backgroundColor: x.background }}
              >
                <div className={styles.icon}>
                  <Icon name={x.icon} size="24" />{" "}
                </div>
                <div className={styles.line}>
                  <div className={styles.details}>
                    <div className={styles.category}>{x.title}</div>
                    <div className={styles.counter}>{x.counter}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>
      <TooltipGlodal />
    </>
  );
};

export default Overview;
