import React from 'react'

function Page403() {
  return (
    <div>
      Sorry You Are Not Authorized!
    </div>
  )
}

export default Page403
