import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./MyLinks.module.sass";
// import img from "../../../../Users/Market/Row/Row.module.sass";
import TooltipGlodal from "../../../../components/TooltipGlodal";
import Card from "../../../../components/Card";
import Icon from "../../../../components/Icon";
import { Switch } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
// import "antd/dist/antd.css";
const items = [
  {
    title: "Views",
    counter: "128",
    icon: "user",
    // value: 37.8,
    background: "#A694F040",
    // chartColor: "#83BF6E",
    data: [
      {
        name: "1",
        earning: 500,
      },
      {
        name: "2",
        earning: 800,
      },
      {
        name: "3",
        earning: 1100,
      },
      {
        name: "4",
        earning: 900,
      },
      {
        name: "5",
        earning: 1300,
      },
      {
        name: "6",
        earning: 800,
      },
      {
        name: "7",
        earning: 1000,
      },
    ],
  },
  {
    title: "Total clicks",
    counter: "128",
    icon: "user",
    // value: 37.8,
    background: "#3CBEA940",
    // chartColor: "#83BF6E",
    data: [
      {
        name: "1",
        earning: 500,
      },
      {
        name: "2",
        earning: 800,
      },
      {
        name: "3",
        earning: 1100,
      },
      {
        name: "4",
        earning: 900,
      },
      {
        name: "5",
        earning: 1300,
      },
      {
        name: "6",
        earning: 800,
      },
      {
        name: "7",
        earning: 1000,
      },
    ],
  },

  {
    title: "Share info",
    counter: "128",
    icon: "user",
    // value: 37.8,
    background: "#FFD88D40",
    // chartColor: "#83BF6E",
    data: [
      {
        name: "1",
        earning: 500,
      },
      {
        name: "2",
        earning: 800,
      },
      {
        name: "3",
        earning: 1100,
      },
      {
        name: "4",
        earning: 900,
      },
      {
        name: "5",
        earning: 1300,
      },
      {
        name: "6",
        earning: 800,
      },
      {
        name: "7",
        earning: 1000,
      },
    ],
  },
  {
    title: "Save contacts",
    counter: "128k",
    icon: "user",
    // value: 37.8,
    background: "#FF6A5540",
    // chartColor: "#83BF6E",
    data: [
      {
        name: "1",
        earning: 500,
      },
      {
        name: "2",
        earning: 800,
      },
      {
        name: "3",
        earning: 1100,
      },
      {
        name: "4",
        earning: 900,
      },
      {
        name: "5",
        earning: 1300,
      },
      {
        name: "6",
        earning: 800,
      },
      {
        name: "7",
        earning: 1000,
      },
    ],
  },
  {
    title: "New following",
    counter: "0",
    icon: "user",
    // value: 37.8,
    background: "#B1E5FC40",
    // chartColor: "#83BF6E",
    data: [
      {
        name: "1",
        earning: 500,
      },
      {
        name: "2",
        earning: 800,
      },
      {
        name: "3",
        earning: 1100,
      },
      {
        name: "4",
        earning: 900,
      },
      {
        name: "5",
        earning: 1300,
      },
      {
        name: "6",
        earning: 800,
      },
      {
        name: "7",
        earning: 1000,
      },
    ],
  },
];




const MyLinks = ({ className ,Links,id}) => {

  const [itemss, setItems] = useState([]);
  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.get(`api/GetUserCounts/${id}`).then((res) => {
        if (res.status === 200) {
          // setUsers(res.data.data);
          const data = res.data.data;
          const updatedItems = items.map((item) => {
            switch (item.title) {
              case "Views":
                return { ...item, counter: data.users };
              case "Total clicks":
                return { ...item, counter: data.clicks };
              case "Share info":
                return { ...item, counter: data.share };
              case "Save contacts":
                return { ...item, counter: data.save_contact };
              case "New following":
                return { ...item, counter: data.following };

              // Add cases for other titles if needed
              default:
                return item;
            }
          });
          setItems(updatedItems);
        }
      });
    });
  }, []);

  
  return (
    <>
      <Card className={cn(styles.card, className)} classTitle="title-purple">
        <div className={`row${styles.overview}`}>
          <div className={styles.list}>
            {itemss.map((x, index) => (
              <div
                className={`col-md-4 ${styles.item}`}
                key={index}
                style={{ backgroundColor: x.background }}
              >
                <div className={styles.icon}>
                  <Icon name={x.icon} size="24" />{" "}
                </div>
                <div className={styles.line}>
                  <div className={styles.details}>
                    <div className={styles.category}>
                      {x.title}
                      {/* <Tooltip
                        className={styles.tooltip}
                        title="Small description"
                        icon="info"
                        place="right"
                      /> */}
                    </div>
                    <div className={styles.counter}>{x.counter}</div>
                    {/* <div className={styles.indicator}>
                      <Balance className={styles.balance} value={x.value} />
                      <span>this week</span>
                    </div> */}
                  </div>
                  {/* <Chart className={styles.chart} item={x} /> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>
      <TooltipGlodal />
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>Logo</div>
          <div className={styles.col}>Link Name</div>
          <div className={styles.col}>Clicks</div>
          <div className={styles.col}>URL</div>
          {/* <div className={styles.col}>Primary</div>
          <div className={styles.col}>Hide</div> */}
          {/* <div className={styles.col}>Edit</div> */}
        </div>
        {Links.map((user) => (user.links.map((link)=>{
          
          return(
          <div className={styles.row} key={link.id}>
            <div className={styles.col}>
              <div className={styles.label}>Logo</div>
              logo
              
            </div>
            <div className={styles.col}>
              <div className={styles.label}>Link Name</div>
              {link.name}
            </div>
            <div className={styles.col}>
              <div className={styles.label}>Clicks</div>
              {link.clicks}
            </div>

            <div className={styles.col}>
              <div className={styles.label}>URL</div>
              {/* Splitting and mapping each part of the URL */}
              <Link to={link.url}>{link.url}</Link>
            </div>

            {/* <div className={styles.col}>
              <div className={styles.label}>Primary</div>
            
              <Switch defaultChecked={link.primary} />
            </div>

            <div className={styles.col}>
              <div className={styles.label}>Hide</div>
              
              <Switch defaultChecked={link.hide} />
            </div>
            <div className={styles.col}>
              <div className={styles.label}>Edit</div>
              <Link to={`/shop/editlink/${link.id}`}>Edit</Link>
            </div> */}
          </div>
        )}))
          
        )}
      </div>
    </>
  );
};

export default MyLinks;
