import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import ModalProduct from "../../../../../../components/ModalProduct";
import { Link } from "react-router-dom";


const Row = ({ item, value, onChange, up }) => {
  const [visibleActions, setVisibleActions] = useState(false);
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);

  return (
    <>
      <div className={styles.row} onMouseLeave={() => setVisibleActions(false)}>
        <div className={styles.col}>
    
            <div className={styles.user}>
              <div className={styles.avatar}>
                {item.image===null ?<img src="/images/content/avatar-1.jpg" alt="Avatar" />:<img src={`http://localhost:8000/storage/${item.image}`} alt="Avatar" />}
                
              </div>
              <div className={styles.details}>
                <div className={styles.man}>
                  <div className={styles.label}>Name</div>
                  {item.name??'x'}</div>
                <div className={styles.login}>
                <div className={styles.label}>Sector</div>
                  {item.sector??'x'}</div>
              </div>
            </div>
        
    
        </div>

        
        <div className={styles.col}>
        <div className={styles.label}>Email</div>
          {item.userprofiles.email}

        </div>

        <div className={styles.col}>
        <div className={styles.label}>Phone Number</div>
          {item.userprofiles.phone??'x'}</div>
        <div className={styles.col}>x</div>

        <div className={styles.col}>
        <div className={styles.label}>Status</div>
          <div className={styles.label}>Status</div>
          {item.status === 'deactive' || item.status === 'Blocked' ? (
            <div className={cn("status-red", styles.status)}>{item.status}</div>
          ) : (
          <div className={cn("status-green", styles.status)}>Active</div>
          )}
        </div>

        <div className={styles.col}>
        <div className={styles.label}>View</div>
          <Link to={`/sales/sales/companies/userprofile/${item.id}`} className={styles.viewbtn}>View</Link>
        </div>
      </div>
      <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
    </>
  );
};

export default Row;
