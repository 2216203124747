import React from "react";
import styles from "./ProductsDashboard.module.sass";
import TooltipGlodal from "../../../../components/TooltipGlodal";
import Overview from "./Overview";
import ListAccount from "../ListAccount";

const ProductsDashboard = () => {
  return (
    <>
      <div className={styles.section}>
        <Overview className={styles.card} />
        <ListAccount />
        <div className={styles.info}></div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default ProductsDashboard;
