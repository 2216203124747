import React, { useEffect, useState } from "react";
import styles from "./LogisticsHome.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import PayoutHistory from "./PayoutHistory";
import axios from "axios";

const LogisticsHome = () => {
  const [users, setUsers] = useState([]);
  const [loading, setloading] = useState(true);
  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.get(`api/GetLogistics`).then((res) => {
        if (res.status === 200) {
          setUsers(res.data.sales);
        }
        setloading(false);
       
      });
    });
  }, [users]);
  return (
    <>
      <div className={styles.section}>
        <PayoutHistory loading={loading} items={users} />
      </div>
      <TooltipGlodal />
    </>
  );
};

export default LogisticsHome;
