import React, { useEffect, useState } from "react";
import styles from "./Market.module.sass";
import Row from "./Row";
import Icon from "../../../../../components/Icon";
import { ScaleLoader } from "react-spinners";

const Market = ({ items, loading, search , cards}) => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters([...selectedFilters, id]);
    }
  };

  useEffect(() => {
    setTotalPages(Math.ceil(items.length / pageSize));
  }, [items, pageSize]);

  if (loading) {
    return (
      <center>
        <ScaleLoader color="#8E59FF" />
      </center>
    );
  } else {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, items.length);
    const visibleItems = items.slice(startIndex, endIndex);
    if(items.length===0){
      return <center>No Users</center>
    }
  return (
    <div className={styles.market}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>Name</div>
          <div className={styles.col}>Email</div>
          <div className={styles.col}>Phone Number</div>
          <div className={styles.col}>Teams</div>
          <div className={styles.col}>Score</div>
          <div className={styles.col}>Status</div>
          <div className={styles.col}>View</div>
        </div>
        {items.filter((item) => {
          return search.toLowerCase() === "" ? item :
            item.profile?.name.toLowerCase().includes(search) ||
            item.email?.toLowerCase().includes(search) ||
            item.phone?.toLowerCase().includes(search) ||
            item.profile.teams?.toLowerCase().includes(search);
        }).map((x, index) => (
          <Row
            item={x}
            cards={cards}
            key={index}
            up={items.length - index <= 2}
            value={selectedFilters.includes(x.id)}
            onChange={() => handleChange(x.id)}
          />
        ))}
      </div>
      {totalPages > 1 && (
          <div className={styles.foot}>
            <button
              className={styles.arrow}
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1} >
              <Icon name="arrow-left" size="20" />
            </button>
            <button
              className={styles.arrow}
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages} >
              <Icon name="arrow-right" size="20" />
            </button>
          </div>
        )}
    </div>
  );
}};

export default Market;
