import React, { useState } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";
import styles from "./Shop.module.sass";
import Profile from "./Profile";
// import Settings from "./Settings";
import Card from "../../../components/Card";
import Dropdown from "../../../components/Dropdown";
// import Filters from "../../components/Filters";
// import Links from "../../components/Product";
import MyLinks from "./MyLinks";
// import Follower from "./Follower";
import Connections from "./Connection";
import ShareInfo from "./ShareInfo";
import ScannedCard from "./ScanCard";
import { useEffect } from "react";
import axios from "axios";
import { RingLoader, ScaleLoader } from "react-spinners";
import DivCard from "../../../components/CardDiv"
import SalesScanned from "../../SalesPage/Shop/ScanCard";
import SalesShareInfo from "../../SalesPage/Shop/ShareInfo";
import SalesConnection from "../../SalesPage/Shop/Connection";
// data
// import { products } from "../../mocks/products";
// import { followers } from "../../mocks/followers";

const navigation = [
  "My Links",
  "Connections",
  "Share Info",
  // "Scanned Card",
  // "All",
];
const intervals = ["Most recent", "Most new", "Most popular"];

const Shop = () => {
  const { id } = useParams();
  const [activeIndex, setActiveIndex] = useState(0);
  const [sorting, setSorting] = useState(intervals[0]);
  const [connections, setConnections] = useState([]);
  const [loading, setloading] = useState(true);
  const [links, setLinks] = useState([]);
  const [share, setShare] = useState([]);
  useEffect(() => {
    axios.get(`api/GetUserProfile/${id}`).then((res) => {
      if (res.data.status === 200) {
        setLinks(res.data.data);
      }
      setloading(false);
      
    });
  }, [id]);

  useEffect(() => {
    axios.get(`api/GetuserShareInfo/${id}`).then((res) => {
      if (res.data.status === 200) {
        setShare(res.data.data);
      }
      setloading(false);
     
    });
  }, [id]);

  useEffect(() => { 
    axios.get(`api/ViewUserFollow/${id}`).then((res) => {
      if (res.data.status === 200) {
        setConnections(res.data.data);
      }
      setloading(false);
      
    });
  }, [id]);

  return (
    <>
      {loading ? (
        <div className={styles.shop}>
          <div className={styles.background}>
            <img src="/images/content/bg-shop.jpg" alt="Background" />
          </div>
          <DivCard className={styles.card}>
            <center>
              <RingLoader color="#36d7b7" />
            </center>
          </DivCard>
        </div>
      ) : (
        <div className={styles.shop}>
          <div className={styles.background}>
            <img src="/images/content/bg-shop.jpg" alt="Background" />
          </div>

          <DivCard className={styles.card}>
            <Profile Links={links} />
            <div className={styles.control}>
              <div className={styles.nav}>
                {navigation.map((x, index) => (
                  <button
                    className={cn(styles.link, {
                      [styles.active]: index === activeIndex,
                    })}
                    onClick={() => setActiveIndex(index)}
                    key={index}
                  >
                    {x}
                  </button>
                ))}
              </div>
              <div className={styles.dropdownBox}>
                {/* <Dropdown
                  className={styles.dropdown}
                  classDropdownHead={styles.dropdownHead}
                  value={sorting}
                  setValue={setSorting}
                  options={intervals}
                  small
                /> */}
              </div>
            </div>
            <div className={styles.wrap}>
              {activeIndex === 0 && <MyLinks Links={links} id={id}/>}
              {activeIndex === 1 && (
                <>
                  <div className={styles.followers}>
                    <SalesConnection connections={connections}/>
                  </div>
                </>
              )}
              {activeIndex === 2 && (
                <>
                  <div className={styles.followers}>
                    <SalesShareInfo share={share}/>
                  </div>
                </>
              )}
              {/* {activeIndex === 3 && (
                <>
                  <div className={styles.followers}>
                    <SalesScanned />
                  </div>
                </>
              )} */}
              {/* {activeIndex === 4 && (
                <>
                  <div className={styles.followers}>
                    <Connections />
                    <ShareInfo />
                    <ScannedCard />
                  </div>
                </>
              )} */}
            </div>
          </DivCard>
        </div>
      )}
    </>
  );
};

export default Shop;
