import React, { useState } from "react";
import styles from "./NewLinks.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import TextInput from "../../../components/TextInput";
import swal from "sweetalert";
import axios from "axios";
import { useNavigate } from "react-router";

const AddCard = () => {
  const history = useNavigate();
  const id = localStorage.getItem('id')
  const username = localStorage.getItem('name')
  const [loading, setLoading] = useState(true)
  const [registerInput, setRegister] = useState({
    name: "",
    // email: "",
    num_card: "",
    earnings: "",
    reference: "",
    error_list: [],
    success: "",
  });

  const handleInput = (e) => {
    setRegister({ ...registerInput, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(false);
    const formData = new FormData();
    formData.append("name", registerInput.name);
    formData.append("seller_name", username);
    formData.append("num_card", registerInput.num_card);
    formData.append("earnings", registerInput.earnings);
    formData.append("reference", registerInput.reference);
    formData.append("user_id", id);

    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios
        .post("api/AddCard", formData, {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Requested-With": "XMLHttpRequest",
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            setRegister({
              ...registerInput,
              success: res.data.message,
              error_list: [],
            });
            swal("Success", res.data.message, "success");
            history('/individual')
          } else if (res.data.status === 422) {
            const validationErrors = res.data.validation_errors;
            let errorMessage = '';
            for (const field in validationErrors) {
              if (validationErrors.hasOwnProperty(field)) {
                validationErrors[field].forEach(error => {
                  errorMessage += `${error}\n`;
                });
              }
            }
            swal('Error', errorMessage, 'error');
          } else {
            setRegister({
              ...registerInput,
              error_list: res.data.validation_errors,
            });
          }
        })
        .catch((error) => {
          console.error("Error registering:", error);
          // Handle registration error
        })
        .finally(() => {
          // Re-enable the button after request completion
          setLoading(true);
        });
    });
  };

  return (
    <Card
      onSubmit={handleSubmit}
      className={styles.card}
      title="Add New Card"
      classTitle={cn("title-purple", styles.title)}
      classCardHead={styles.head}
    >
      <TextInput
        className={styles.field}
        label="For *"
        name="name"
        type="text"
        required
        onChange={handleInput}
        value={registerInput.name}
      />
      <TextInput
        className={styles.field}
        label="Number of card *"
        name="num_card"
        type="number"
        required
        onChange={handleInput}
        value={registerInput.num_card}
      />
      <TextInput
        className={styles.field}
        label="Cost ( USD ) *"
        name="earnings"
        type="number"
        required
        onChange={handleInput}
        value={registerInput.earnings}
      />
      <TextInput
        className={styles.field}
        label="Transaction reference *"
        name="reference"
        type="text"
        required
        onChange={handleInput}
        value={registerInput.reference}
      />

      {loading ?
        <button type="submit" className={cn("button", styles.button)}>
          Add new Card
        </button>
        :
        <button type="submit" disabled className={cn("button", styles.button)}>
          Loading...
        </button>}
    </Card>
  );
};

export default AddCard;
