import React, { useState } from "react";
import styles from "./Control.module.sass";
import styless from "./Control.module.css";
import cn from "classnames";
import Actions from "../../../components/Actions";
import Popup from "reactjs-popup";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Icon from "../../../components/Icon";

const Control = ({
  className,
  visibleActions,
  setVisibleActions,
  up,
  item,
}) => {
  const deleteAccount = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will Hold this Company!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#8E59FF",
      confirmButtonText: "Yes, Hold it!",
      cancelButtonText: "No, cancel !",
      closeOnConfirm: false,
      closeOnCancel: false,
    }).then((result) => {
      if (result.isConfirmed) {
        e.preventDefault();
        // If user confirms deletion, proceed with deletion
        axios.get("/sanctum/csrf-cookie").then((response) => {
          axios.post(`api/HoldCompany/${item.id}`).then((res) => {
            if (res.data.status === 200) {
              // Display success message
              Swal.fire("Hold!", "Company has been Holded.", "success");
              window.location.reload();
            }
          });
        });
      } else {
        // If user cancels, show cancellation message
        Swal.fire("Cancelled", "Your Company is safe :)", "error");
      }
    });
  };
  const UnHoldCompany = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will UnHold this Company!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes, UnHold it!",
      cancelButtonText: "No, cancel !",
      closeOnConfirm: false,
      closeOnCancel: false,
    }).then((result) => {
      if (result.isConfirmed) {
        e.preventDefault();
        // If user confirms deletion, proceed with deletion
        axios.get("/sanctum/csrf-cookie").then((response) => {
          axios.post(`api/UnHoldCompany/${item.id}`).then((res) => {
            if (res.data.status === 200) {
              // Display success message
              Swal.fire("Hold!", "Company has been Active.", "success");
              window.location.reload();
            }
          });
        });
      } else {
        // If user cancels, show cancellation message
        Swal.fire("Cancelled", "Your Company is Still Holded :)", "error");
      }
    });
  };

  // DURATION INPUT
  const [duration, setDuration] = useState("");

  const handleDurationChange = (e) => {
    const inputDuration = e.target.value;
    setDuration(inputDuration);
  };
  // POP UP Block
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };
  // POP UP Delete
  const open2Modal = () => {
    setOpen2(true);
  };

  const close2Modal = () => {
    setOpen2(false);
  };
  // ACTIONS
  const actions = [


    {
      title: "Hold",
      icon: "message",
      action: open2Modal,
    },
  ];
  const actions2 = [
    {
      title: "Edit product",
      icon: "edit",
      action: () => {},
    },
    {
      title: "View comment",
      icon: "message",
      action: () => console.log("View comment"),
    },
    // {
    //   title: "Block",
    //   icon: "edit",
    //   action: openModal,
    // },

    {
      title: "Unhold",
      icon: "message",
      action: open2Modal,
    },
  ];

  return (
    <div className={cn(styles.control, className)}>
     {/* <Link to={`/EditCompany/${item.id}`} className={styles.button}>
        <Icon name="edit" size="20" />
      </Link> */}

     {
      item.hold === 0 ?  <Actions
        className={styles.actions}
        classActionsHead={styles.actionsHead}
        classActionsBody={styles.actionsBody}
        classActionsOption={styles.actionsOption}
        items={actions}
        visible={visibleActions}
        setVisible={setVisibleActions}
        up={up}
      />:
       <Actions
        className={styles.actions}
        classActionsHead={styles.actionsHead}
        classActionsBody={styles.actionsBody}
        classActionsOption={styles.actionsOption}
        items={actions2}
        visible={visibleActions}
        setVisible={setVisibleActions}
        up={up}
      />
     }

      {item.hold === 0 ? (
        <Popup open={open2} closeOnDocumentClick onClose={close2Modal}>
          <div className={styless.overlay}></div>
          <div className={styless.modal}>
            <p className={styless.close} onClick={close2Modal}>
              &times;
            </p>

            <div className={styless.header}>Hold</div>
            <div className={styless.content}>{item.company_info.name}</div>

            <button onClick={deleteAccount} className={styless.buttonstyles}>
              Hold
            </button>
          </div>
        </Popup>
      ) : (
        <Popup open={open2} closeOnDocumentClick onClose={close2Modal}>
          <div className={styless.overlay}></div>
          <div className={styless.modal}>
            <p className={styless.close} onClick={close2Modal}>
              &times;
            </p>

            <div className={styless.header}>UnHold</div>
            <div className={styless.content}>{item.company_info.name}</div>

            <button onClick={UnHoldCompany} className={styless.buttonstyles}>
              UnHold
            </button>
          </div>
        </Popup>
      )}
    </div>
  );
};

export default Control;
