import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Account.module.sass";
import Item from "./Item";
import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";
import Editor from "../../../components/Editor";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import swal from "sweetalert";

const Account = ({ className }) => {
  const [users, setUsers] = useState([]);
  const [name, setname] = useState([]);
  const [loading, setloading] = useState(true);
  const { id } = useParams();
  const history = useNavigate();

  const handleInput = (e) => {
    setUsers({ ...users, [e.target.name]: e.target.value });
  };
  const handleInput2 = (e) => {
    setname({ ...name, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.get(`api/ViewAccount/${id}`).then((res) => {
        if (res.status === 200) {
          setUsers(res.data.data);
          setname(res.data.name);
        } else if (res.data.status === 404) {
          swal("Oops!", res.data.message, "error");
          history(-1);
        }
        setloading(false);
        // setTimeout(() => {
        // }, 2000);
      });
    });
  }, [id]);

  // ProfilePic UPLOAD PIC
  const [selectedImage2, setSelectedImage2] = useState(
    "/images/content/avatar.jpg"
  );

  // ProfilePic UPLOAD PIC
  const handleFileChange2 = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const newImageUrl = e.target.result;
        setSelectedImage2(newImageUrl);
      };

      reader.readAsDataURL(file);
    }
  };

  const updateProfilePicture = () => {
    // Make sure an image has been selected
    if (selectedImage2 !== "/images/content/avatar.jpg") {
      // Prepare form data to send to the server
      const formData = new FormData();
      formData.append('image', selectedImage2);

      // Send a POST request to the update API endpoint
      axios.post(`api/updateProfilePicture/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(response => {
          // Handle success response
          console.log('Profile picture updated successfully:', response.data);
          // Optionally, update UI or show a success message
        })
        .catch(error => {
          // Handle error
          console.error('Error updating profile picture:', error);
          // Optionally, show an error message to the user
        });
    } else {
      // Optionally, show a message to the user indicating that no new image has been selected
      console.log('No new image selected.');
    }
  };
  const UpdateLink = (e) => {
    e.preventDefault();

    const data = {
      ...users,
      ...name,
    };

    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.put(`api/UpdateAccount/${id}`, data).then((res) => {
        if (res.data.status === 200) {
          swal("Success!", "Account Updated Successfully", "success");
          history('/account');
        } else if (res.data.status === 422) {
          swal("Oops!", "Fields is Required", "error");
        }
      });
    });
  };

  return (
    <div className={styles.account}>
      <div className={styles.accountChildreen}>
        <Item
          className={cn(styles.card, className)}
          title="Edit"
          classTitle="title-purple"
        >
          {/* <form onSubmit={updateProfilePicture}>
          
          <div className={styles.profile}>
            <div className={styles.avatar}>
              <img src={selectedImage2} alt="Avatar" />
            </div>
            <div className={styles.file}>
              <input type="file" onChange={handleFileChange2} name="image" />
              <button className={cn("button", styles.button)} type="button" >
                <Icon name="add" size="24" />
                <span>Upload New picture</span>
              </button>
            </div>
          </div>
        </form> */}
          <form onSubmit={UpdateLink}>
            <div className={styles.fieldset}>
              <TextInput
                className={styles.field}
                label="Name"
                name="name"
                type="text"
                required
                value={name.name}
                onChange={handleInput2}
              />

              <TextInput
                className={styles.field}
                label="Email "
                name="email"
                type="email"
                required
                value={users.email}
                onChange={handleInput}
              />
              {/* <TextInput
                className={styles.field}
                label=" Password"
                name="password"
                type="password"
                // required
                value={users.password}
                onChange={handleInput}
              /> */}
            </div>
            <button
              type="submit"
              className={cn("button", styles.button)}
              style={{ marginTop: "2rem", marginBottom: "2rem" }}
            >
              Edit
            </button>
          </form>
        </Item>
      </div>
    </div>
  );
};

export default Account;
