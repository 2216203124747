import React, { useState } from "react";
import styles from "./NewLinks.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Icon from '../../../components/Icon'
import TextInput from "../../../components/TextInput";
import swal from "sweetalert";
import axios from "axios";
import { useNavigate, useParams } from "react-router";

const NewLinks = () => {
  const { id } = useParams();
  const history = useNavigate();
  const [linkInput, setlink] = useState({
    type: "",
    name: "",
    url: "",
    error_list: [],
    sucess: "",
  });

  const handleInput = (e) => {
    // e.presist();
    setlink({ ...linkInput, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create FormData object
    const formData = new FormData();
    formData.append("type", linkInput.type);
    formData.append("name", linkInput.name);
    formData.append("url", linkInput.url);

    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios
        .post(`api/createLink/${id}`, formData, {
          withCredentials: true, // Include cookies with the request
          headers: {
            "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
            "X-Requested-With": "XMLHttpRequest",
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            console.log("success");
            setlink({
              ...linkInput,
              sucess: res.data.message,
              error_list: [],
            });
            swal("Success!", "Link Created Successfully!", "success");
            history(-1)
          }else if(res.data.status === 422){
            swal("Oops!", "Fields is Required", "error");
          } else {
            setlink({
              ...linkInput,
              error_list: res.data.errors,
            });
            swal("Oops!", res.data.message, "error");
          }
        })
        .catch((error) => {
          console.error("Error registering:", error);
          // Handle registration error
        });
    });
  };
  return (
    <Card
      onSubmit={handleSubmit}
      className={styles.card}
      title="New Link"
      classTitle={cn("title-purple", styles.title)}
      classCardHead={styles.head}
    >
      <label className={styles.label}>Type</label>

      <select
        id="Sector"
        className={styles.field}
        name="type"
        required
        onChange={handleInput}
        value={linkInput.type}
      >
        <option value="" label="select"></option>
        <option value="facebook" label="Facebook"><Icon name='facebook'/> Facebook</option>
        <option value="instagram" label="Instagram"><Icon name='facebook'/> Instagram</option>
        <option value="threads" label="Threads"><Icon name='facebook'/> Threads</option>
        <option value="sky" label="Skype"><Icon name='facebook'/> Skype</option>
        <option value="youtube" label="Youtube"><Icon name='facebook'/> Youtube</option>
        <option value="linkedin" label="Linkedin"><Icon name='facebook'/> Linkedin</option>
        <option value="reddit" label="Reddit"><Icon name='facebook'/> Reddit</option>
        <option value="snapchat" label="Snapchat"><Icon name='facebook'/> Snapchat</option>
        <option value="tiktok" label="Tiktok"><Icon name='facebook'/> Tiktok</option>
        <option value="tiktok" label="Tiktok"><Icon name='facebook'/> X</option>


        <option value="x" label="X"><Icon name='X'/> X</option>


      </select>

      <TextInput
        className={styles.field}
        label="Link Name "
        name="name"
        type="text"
        required
        onChange={handleInput}
        value={linkInput.name}
      />
      <TextInput
        className={styles.field}
        label="URL "
        name="url"
        type="text"
        required
        onChange={handleInput}
        value={linkInput.url}
      />

      <button type="submit" className={cn("button", styles.button)}>Add new link</button>
    </Card>
  );
};

export default NewLinks;
