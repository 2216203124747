import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import ModalProduct from "../../../../components/ModalProduct";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

const Row = ({ item, loading, value, onChange, up }) => {
  const [visibleActions, setVisibleActions] = useState(false);
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);
  const history = useNavigate();
  const ReturnAccount = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will Return this Account!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#8E59FF",
      confirmButtonText: "Yes, Return it!",
      cancelButtonText: "No, cancel !",
      closeOnConfirm: false,
      closeOnCancel: false,
    }).then((result) => {
      if (result.isConfirmed) {
        e.preventDefault();
        // If user confirms deletion, proceed with deletion
        axios.get("/sanctum/csrf-cookie").then((response) => {
          axios.post(`api/UnArchiveUser/${item.id}`).then((res) => {
            if (res.data.status === 200) {
              // Display success message
              Swal.fire("Deleted!", "Account has been Returned.", "success");
              // history(-1);
              window.location.reload();
            }
          });
        });
      } else {
        // If user cancels, show cancellation message
        Swal.fire("Cancelled", "Your Account is still Deleted :)", "error");
      }
    });
  };

  return (
    <>
      <div className={styles.row} onMouseLeave={() => setVisibleActions(false)}>
        <div className={styles.col}>
          <div className={styles.user}>
            <div className={styles.avatar}>
            {
                item.image === null ? <img src="/images/logo-light.png" alt="Avatar" /> :
                  <img src={`https://daleelnfc.com/storage/${item.profile.image}`} alt="Avatar" />
              }
            </div>
            <div className={styles.details}>
              <div className={styles.man}>
                <div className={styles.label}>Name</div>
                {item.profile.name}</div>
              <div className={styles.login}>
              <div className={styles.label}>Sector</div>
                {item.profile.sector ?? "x"}</div>
            </div>
          </div>
        </div>

        <div className={styles.col}>
        <div className={styles.label}>Email</div>
          {item.email}</div>

        <div className={styles.col}>
        <div className={styles.label}>Company</div>
          {item.phone ?? "x"}</div>
        <div className={styles.col}>{item.profile.company ?? "x"}</div>

        <div className={styles.col}>
          <div className={styles.label}>Status</div>
          {item.archive === 1 ? (
            <div className={cn("status-red", styles.status)}>Deactive</div>
          ) : (
            <div className={cn("status-green", styles.status)}>Active</div>
          )}
        </div>

        <div className={`${styles.col} ${styles.returnbtn}`}>
        <div className={styles.label}>Return</div>
          <div onClick={ReturnAccount}>Return</div>{" "}
        </div>
      </div>
      <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
    </>
  );
};

export default Row;
