import React, { useEffect, useState } from "react";
import styles from "./ShareInfo.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Form from "../../../components/Form";
import Market from "./Market";
import Table from "./Table";
import { traffic } from "../../../mocks/traffic";
import { viewers } from "../../../mocks/viewers";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Dropdown from "../../../components/Dropdown";
// Sorting
const intervals = [
  "A",
  "Z",
];

const ShareInfo = () => {
  const navigation = ["Viewers"];

  const cid = localStorage.getItem('company_id')

  const [activeTab, setActiveTab] = useState(navigation[0]);
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setloading] = useState(true);
  const [sortDirection, setSortDirection] = useState("asc"); // Default to ascending
  const [filter, setFilter] = useState("A"); // Default filter

  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.get(`api/shareInfoCompany?cid=${cid}`).then(res => {
        if (res.data.status === 200) {
          setUsers(res.data.content);
        }
        setloading(false)

      });
    });
  }, [])

  const toggleSortDirection = () => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  // Function to handle filter change
  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };
  const sortData = () => {
    return users.slice().sort((a, b) => {
      const nameA = a.email.toLowerCase();
      const nameB = b.email.toLowerCase();
      if (filter === 'A') {
        return sortDirection === "asc" ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
      } else if (filter === 'Z') {
        return sortDirection === "asc" ? nameB.localeCompare(nameA) : nameA.localeCompare(nameB);
      }
      return 0;
    });
  };


  // PDF FUNCTION
  const handleExtract = (event) => {
    event.preventDefault();  // Prevent default form submission behavior

    const data = sortData().map((c) => [
      c.name,
      c.sector,
      c.email,
      c.phone,
      c.num_cards,
      c.company,
      c.from
    ]);

    const doc = new jsPDF();
    doc.autoTable({
      head: [["Name", "Sector", "Email", "Phone Number", "Companies", "From"]],
      body: data
    });

    doc.save("Share Info.pdf");
  };


  return (
    <Card
      className={styles.card}
      title="Share info"
      classTitle={cn("title-purple", styles.title)}
      classCardHead={styles.head}
      head={
        <>
          <Form
            className={styles.form}
            value={search}
            setValue={setSearch}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search..."
            type="text"
            name="search"
            icon="search"
          />

          <Dropdown
            className={`${styles.dropdown} ${styles.newStyle}`}
            classDropdownHead={styles.dropdownHead}
            value={filter}
            setValue={handleFilterChange}
            options={intervals}
            small
          />

          <div className={styles.contacts}>
            {/* <button  className={cn("button", styles.button)} onClick={handleExtract}>
              Extract
            </button> */}
          </div>
        </>
      }
    >
      <div className={styles.products}>
        <div className={styles.wrapper}>
          {activeTab === navigation[0] && <Market items={sortData()} loading={loading} search={search} />}
          {activeTab === navigation[1] && (
            <Table
              title="Traffic source"
              items={traffic}

            />
          )}
          {activeTab === navigation[2] && (
            <Table title="Viewers" items={viewers} />
          )}
        </div>
      </div>
    </Card>
  );
};

export default ShareInfo;
