import React, { createContext, useContext, useEffect, useState } from 'react';
import styles from "./AddCompany.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import DivCard from "../../../components/CardDiv";
import TextInput from "../../../components/TextInput";
import Icon from "../../../components/Icon";
import axios from "axios";
import { useNavigate, useParams } from "react-router";
import swal from "sweetalert";

const EditCompany = ({ validUserIds }) => {
  const [Userinputs, setUser] = useState([]);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(true)
  const { id } = useParams();
  const history = useNavigate();

  
  const handleInput = (e) => {
    setUser({ ...Userinputs, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.get(`api/ViewUserToedit/${id}`).then((res) => {
        if (res.data.status === 200) {
          setUser(res.data.data);

        } else if (res.data.status === 404) {
          swal("Oops!", res.data.message, "error");
          history(-1);
        }
      });
    });
  }, [id]);

  const UpdateLink = (e) => {

    e.preventDefault();
    setLoading(false)
    const data = {
      ...Userinputs,
      // ...name,
    };

    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.put(`api/UpdateUserAccount/${id}`, data).then((res) => {
        if (res.data.status === 200) {
          swal("Success!", "Account Updated Successfully", "success");
          setLoading(true)
          history(-1);
        } else if (res.data.status === 422) {
          swal("Oops!", "Fields is Required", "error");
          setLoading(true)
        }
      });
    });
  };
  return (
    <>
      <form onSubmit={UpdateLink} encType="multipart/form-data">
        <DivCard
          className={styles.card}
          title="Edit User"
          classTitle={cn("title-purple", styles.title)}
          classCardHead={styles.head}
        >
          {/* <div className={styles.profile}>
            <div className={styles.avatar}>
              <img src={selectedImage2} alt="Avatar" />
              
            </div>
            <div className={styles.file}>
              <input
                type="file"
                onChange={handleMerge}
                name="image"
                accept="image/*"
              />
              <button className={cn("button", styles.button)} type="button">
                <Icon name="add" size="24" />
                <span>Upload New picture</span>
              </button>
            </div>
          </div> */}
          <TextInput
            className={styles.field}
            label="User Name "
            name="name"
            type="text"
            required
            onChange={handleInput}
            value={Userinputs.name}
          />
          <div className={styles.inputrow}>
            <TextInput
              className={`${styles.field} ${styles.rowfield}`}
              label="Sector "
              name="sector"
              type="text"
              required
              onChange={handleInput}
              value={Userinputs.sector}
            />
            {/* {console.log(Userinputs.user.phone)} */}

            <TextInput
              className={`${styles.field} ${styles.rowfield}`}
              label="Phone Number"
              name="phone"
              placeholder={(Userinputs.user && Userinputs.user.phone) ?? "This User don't have a phone number"}
              type="number"
              onChange={handleInput}
              value={Userinputs.user && Userinputs.user.phone ? Userinputs.user.phone : null}
            />

          </div>
          <div className={styles.inputrow}>
            <TextInput
              className={`${styles.field} ${styles.rowfield}`}
              label="Expertise "
              name="expertise"
              type="text"
              required
              onChange={handleInput}
              value={Userinputs.expertise}
            />
            <TextInput
              className={`${styles.field} ${styles.rowfield}`}
              label="Bio"
              name="bio"
              type="text"
              required
              onChange={handleInput}
              value={Userinputs.bio}
            />
          </div>
         { loading?
          <button type="submit" className={cn("button", styles.button)}>
            Edit User
          </button>
          :
          <button type="submit" disabled className={cn("button", styles.button)}>
            Loading
          </button>}
        </DivCard>


      </form>
    </>
  );
};

export default EditCompany;
