import React from "react";
import cn from "classnames";
import styles from "./Profile.module.sass";
// import Icon from "../../../../../components/Icon";
import { Link } from "react-router-dom";
// import Popup from 'reactjs-popup';
// import { Switch } from "antd";

const Profile = ({ Links }) => {
  return (
    <div>
      {Links.map((x, index) => (
        <div className={styles.profile}>
          <div className={styles.details}>
            <div className={styles.avatar}>
              {
                x.image ? <img src={`https://daleelnfc.com/storage/${x.image}`} alt="Avatar" />
                  :
                  <img src="/images/logo-light.png" alt="Avatar" />
              }
              {/* <button className={styles.add}>
            <Icon name="add" />
          </button> */}
            </div>
            <div className={styles.wrap}>
              <div className={cn("h4", styles.man)}>{x.name}</div>
              <div className={styles.info}>{x.sector}</div>
            </div>
          </div>
          <div className={styles.contacts}>
            <Link to={`/company/company-newlinks/${x.id}`} className={cn("button", styles.button)}>
              Add New Link
            </Link>
            {/* <Link to="" className={cn("button", styles.button)}>
          Edit-ability
        </Link> */}
            {/* <Popup
    trigger={<button className={cn("button", styles.button)}>Edit-ability</button>}
    modal
    nested
  >
    {close => (
      <div className={styles.modal}>
        <button className={styles.close} onClick={close}>
          &times;
        </button>
        <div className={styles.header}> Edit Ability </div>
        <div className={styles.content}>
          {' '}
        
        </div>
        <div className={styles.actions}>
       
          <button
            className={styles.button}
            onClick={() => {
              console.log('modal closed ');
              close();
            }}
          >
            close modal
          </button>
        </div>
      </div>
    )}
  </Popup> */}
            {/*  */}
            {/* <Link to="/company-editlinks" className={cn("button", styles.button)}>
        Edit
        </Link> */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Profile;
