import React, { useState } from "react";
import styles from "./Row.module.sass";
import ModalProduct from "../../../../../components/ModalProduct";


const Row = ({ up, item }) => {
  const [visibleActions, setVisibleActions] = useState(false);
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);

  console.log(item);
  return (
    <>
      <div className={styles.row} onMouseLeave={() => setVisibleActions(false)}>
        <div className={styles.col}>
          <div className={styles.user}>
            <div className={styles.avatar}>
              {
                item.image ? 
                <img src={`https://daleelnfc.com/storage/${item.image}`} alt="Avatar" />
                :
                <img src="/images/logo-light.png" alt="Avatar" /> 
              }
            </div>
            <div className={styles.details}>
              <div className={styles.man}>
                <div className={styles.label}>Name</div>
                {item.name ?? 'x'}</div>
              <div className={styles.login}>
                <div className={styles.label}>Sector</div>
                {item.sector ?? 'x'}</div>
            </div>
          </div>
          <div className={styles.details}></div>
        </div>
        <div className={styles.col}>
          <div className={styles.label}>Email</div>
          {item.email ?? 'x'}</div>
        <div className={styles.col}>
          <div className={styles.label}>Phone</div>
          {item.phone ?? 'x'}</div>
        <div className={styles.col}>
          <div className={styles.label}>Company</div>
          {item.company ?? 'x'}</div>
        <div className={styles.col}>
          <div className={styles.label}>From</div>
          {item.from ?? 'x'}</div>
      </div>
      <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
    </>
  );
};

export default Row;
