import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Control.module.sass";
import styless from "./Control.module.css";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import Actions from "../../../../components/Actions";
import Popup from "reactjs-popup";
import axios from "axios";
import swal from "sweetalert";
import Swal from "sweetalert2";

const Control = ({
  className,
  visibleActions,
  setVisibleActions,
  up,
  item,
}) => {

  const deleteAccount = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will Delete this Account!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#8E59FF",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel !",
      closeOnConfirm: false,
      closeOnCancel: false,
    }).then((result) => {
      if (result.isConfirmed) {
        e.preventDefault();
        // If user confirms deletion, proceed with deletion
        axios.get("/sanctum/csrf-cookie").then((response) => {
          axios.post(`api/ArchiveUser/${item.userprofiles.id}`).then((res) => {
            if (res.data.status === 200) {
              // Display success message
              Swal.fire("Deleted!", "Account has been deleted.", "success");
              window.location.reload();
            }
          });
        });
      } else {
        // If user cancels, show cancellation message
        Swal.fire("Cancelled", "Your Account is safe :)", "error");
      }
    });
  };

  const history = useNavigate();
  const [registerInput, setRegister] = useState({
    description: "",
    error_list: [],
    sucess: "",
  });
  const handleInput = (e) => {
    // e.presist();
    setRegister({ ...registerInput, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create FormData object
    const formData = new FormData();
    formData.append("description", registerInput.description);

    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios
        .post(`api/BlockUser/${item.id}`, formData, {
          withCredentials: true, // Include cookies with the request
          headers: {
            "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
            "X-Requested-With": "XMLHttpRequest",
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            setRegister({
              ...registerInput,
              sucess: res.data.message,
              error_list: [],
            });
            swal("Success", res.data.message, "success");
            // history(`/list-account/${item.id}`);
            window.location.reload();
          } else if (res.data.message === 422) {
            swal("Error", res.data.validation_errors, "error");
          } else {
            setRegister({
              ...registerInput,
              error_list: res.data.validation_errors,
            });
          }
        })
        .catch((error) => {
          console.error("Error registering:", error);
          // Handle registration error
        });
    });
  };
  // DURATION INPUT
  const [duration, setDuration] = useState("");

  const handleDurationChange = (e) => {
    const inputDuration = e.target.value;
    setDuration(inputDuration);
  };
  // POP UP Block
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };
  // POP UP Delete
  const open2Modal = () => {
    setOpen2(true);
  };

  const close2Modal = () => {
    setOpen2(false);
  };
  // ACTIONS
  const actions = [

    {
      title: "Block",
      icon: "edit",
      action: openModal,
    },

    {
      title: "Delete",
      icon: "trash",
      action: open2Modal,
    },
  ];

  return (
    <div className={cn(styles.control, className)}>
      <Link to={`/EditUser/${item.userprofiles.id}`} className={styles.button}>
        <Icon name="edit" size="20" />
      </Link>

      <Actions
        className={styles.actions}
        classActionsHead={styles.actionsHead}
        classActionsBody={styles.actionsBody}
        classActionsOption={styles.actionsOption}
        items={actions}
        visible={visibleActions}
        setVisible={setVisibleActions}
        up={up}
      />

      <Popup open={open} closeOnDocumentClick onClose={closeModal}>
        <div className={styless.overlay}></div>
        <div className={styless.modal}>
          <p className={styless.close} onClick={closeModal}>
            &times;
          </p>

          <div className={styless.header}>Block</div>
          <div className={styless.content}>{item.name}</div>
          <div className={styless.footer}>Duration</div>
          <form onSubmit={handleSubmit}>
            <div className={styless.duration}>
              <input
                type="text"
                id="durationInput"
                name="description"
                onChange={handleInput}
                value={registerInput.description}
                required
              />
            </div>
            <button type="submit" className={styless.buttonstyles}>
              Block
            </button>
          </form>
        </div>
      </Popup>

      <Popup open={open2} closeOnDocumentClick onClose={close2Modal}>
        <div className={styless.overlay}></div>
        <div className={styless.modal}>
          <p className={styless.close} onClick={close2Modal}>
            &times;
          </p>

          <div className={styless.header}>Delete</div>
          <div className={styless.content}>{item.name}</div>

          <button onClick={deleteAccount} className={styless.buttonstyles}>
            Delete
          </button>
        </div>
      </Popup>
    </div>
  );
};

export default Control;
