import React, { useEffect, useState } from "react";
import styles from "./SalesIndividual.module.sass";
import TooltipGlodal from "../../../components/TooltipGlodal";
import PayoutHistory from "./PayoutHistory";
import axios from "axios";

const SalesIndividual = () => {
  const id = localStorage.getItem('id');

  const [users, setUsers] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.get(`api/FetchIndividual?id=${id}`).then(res => {
        if (res.status === 200) {
          setUsers(res.data.data);
        }
        setloading(false)
        
      });
    });
  }, [])
  return (
    <>
      <div className={styles.section}>
        <PayoutHistory loading={loading} items={users} />
      </div>
      <TooltipGlodal />
    </>
  );
};

export default SalesIndividual;

