import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Account.module.sass";
import Item from "./Item";
import Icon from "../../../../components/Icon";
import TextInput from "../../../../components/TextInput";
import axios from "axios";
import DivCard from "../../../../components/CardDiv"
import swal from "sweetalert";
import { useNavigate } from "react-router";

const sectors = [
  "Energy Equipment & Services",
  "Oil, Gas & Consumable Fuels",
  "Chemicals",
  "Construction Materials",
  "Containers & Packaging",
  "Metals & Mining",
  "Paper & Forest Products",
  "Aerospace & Defense",
  "Building Products",
  "Construction & Engineering",
  "Electrical Equipment",
  "Industrial Conglomerates",
  "Machinery",
  "Trading Companies & Distributors",
  "Commercial Services & Supplies",
  "Professional Services",
  "Air Freight & Logistics",
  "Airlines",
  "Marine",
  "Road & Rail",
  "Transportation Infrastructure",
  "Auto Components",
  "Automobiles",
  "Household Durables",
  "Leisure Products",
  "Textiles, Apparel & Luxury Goods",
  "Hotels, Restaurants & Leisure",
  "Diversified Consumer Services",
  "Distributors",
  "Internet & Direct Marketing Retail",
  "Multiline Retail",
  "Specialty Retail",
  "Food & Staples Retailing",
  "Beverages",
  "Food Products",
  "Tobacco",
  "Household Products",
  "Personal Products",
  "Health Care Equipment & Supplies",
  "Health Care Providers & Services",
  "Health Care Technology",
  "Biotechnology",
  "Pharmaceuticals",
  "Life Sciences Tools & Services",
  "Banks",
  "Thrifts & Mortgage Finance",
  "Diversified Financial Services",
  "Consumer Finance",
  "Capital Markets",
  "Mortgage Real Estate Investment Trusts (REITs)",
  "Insurance",
  "IT Services",
  "Software",
  "Communications Equipment",
  "Technology Hardware, Storage & Peripherals",
  "Electronic Equipment, Instruments & Components",
  "Semiconductors & Semiconductor Equipment",
  "Diversified Telecommunication Services",
  "Wireless Telecommunication Services",
  "Media",
  "Entertainment",
  "Interactive Media & Services",
  "Electric Utilities",
  "Gas Utilities",
  "Multi-Utilities",
  "Water Utilities",
  "Independent Power and Renewable Electricity Producers",
  "Equity Real Estate Investment Trusts (REITs)",
  "Real Estate Management & Development"
];
const Account = ({ className }) => {
  // const [content, setContent] = useState();
  const [Image, setImage] = useState([]);
  const [BanerImage, setBanerImage] = useState([]);
  const [loading, setLoading] = useState(true)

  const history = useNavigate();
  // BACKGROUN UPLOAD PIC
  const [selectedImage, setSelectedImage] = useState(
    "/images/content/bg-shop.png"
  );
  // ProfilePic UPLOAD PIC
  const [selectedImage2, setSelectedImage2] = useState(
    "/images/logo-dark.png"
  );
  
  const company_id = localStorage.getItem('company_id');
  const [teams, setTeams] = useState([]);
  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.get(`api/fetchcompanyteams?cid=${company_id}`).then(res => {
        if (res.data.status === 200) {
          setTeams(res.data.content);
        }

      });
    });
  }, [])
  // BACKGROUN UPLOAD PIC
  const [registerInput, setRegister] = useState({
    name: "",
    email: "",
    password: "",
    expertise: "",
    sector: "",
    // bio: "",
    teams: "",
    error_list: [],
    sucess: "",
  });

  const handleInput = (e) => {
    // e.presist();
    setRegister({ ...registerInput, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(false)
    // Create FormData object
    const formData = new FormData();
    formData.append("name", registerInput.name);
    formData.append("email", registerInput.email);
    formData.append("password", registerInput.password);
    formData.append("sector", registerInput.sector);
    formData.append("expertise", registerInput.expertise);
    // formData.append("bio", registerInput.bio);
    formData.append("teams", registerInput.teams);
    formData.append("role", 'user');
    formData.append("company_ID", company_id);
    formData.append("image", Image.image);
    formData.append("banner", BanerImage.bannerimage);

    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios
        .post("api/addUser", formData, {
          withCredentials: true, // Include cookies with the request
          headers: {
            "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
            "X-Requested-With": "XMLHttpRequest",
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            // console.log("success");
            setRegister({
              ...registerInput,
              sucess: res.data.message,
              error_list: [],
            });
            setLoading(true)
            swal('Success', res.data.headers, 'success')
            history('/company')
          } else if (res.data.status === 422) {
            swal("Warning", "Fields Required", "error")
            setLoading(true)
          } else {
            setRegister({
              ...registerInput,
              error_list: res.data.errors,
            });
            swal('Error', res.data.headers, 'error')
          }
        })
        .catch((error) => {
          console.error("Error registering:", error);
          // Handle registration error
        });
    });
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const newImageUrl = e.target.result;
        setSelectedImage(newImageUrl);
      };

      reader.readAsDataURL(file);
    }
  };
  // ProfilePic UPLOAD PIC
  const handleFileChange2 = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const newImageUrl = e.target.result;
        setSelectedImage2(newImageUrl);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleImage2 = (event) => {
    setImage({ image: event.target.files[0] })
  }
  const handleImag = (event) => {
    setBanerImage({ bannerimage: event.target.files[0] })
  }
  const handleMerge = (event) => {
    // Call the first function
    handleFileChange(event);

    // Call the second function
    handleImag(event);
  };

  const handleMerge2 = (event) => {
    // Call the first function
    handleFileChange2(event);

    // Call the second function
    handleImage2(event);
  };
  return (
    <form
      className={styles.account}
      onSubmit={handleSubmit}
      encType="multipart/form-data"
    >
      <div className={styles.account}>
        <div className={styles.accountChildreen}>
          <Item
            className={cn(styles.card, className)}
            title="Add new user"
            classTitle="title-purple"
          >
            <div className={styles.background}>
              <div className={styles.backgroundPic}>
                <img src={selectedImage} alt="Avatar" className={styles.bgimg} />
              </div>

              <div className={styles.file1}>
                <input type="file" onChange={handleMerge} name="banner" accept="image/*" />
                <button className={cn("button", styles.button)} type="button">
                  <Icon name="add" size="24" />
                  <span>Upload New picture</span>
                </button>
              </div>
            </div>

            <div className={styles.profile}>
              <div className={styles.avatar}>
                <img src={selectedImage2} alt="Avatar" />
              </div>
              <div className={styles.file}>
                <input type="file" onChange={handleMerge2} name="image" accept="image/*" />
                <button className={cn("button", styles.button)} type="button">
                  <Icon name="add" size="24" />
                  <span>Upload New picture</span>
                </button>
              </div>
            </div>
            <div className={styles.fieldset}>
              <TextInput
                className={styles.field}
                label="Name"
                name="name"
                type="text"
                required
                onChange={handleInput}
                value={registerInput.name}
              />

              <label className={styles.label}>Sector</label>
              <select
                id="Sector"
                className={styles.field}
                name="sector"
                required
                onChange={handleInput}
                value={registerInput.sector}
              >
                <option value="" label="Select"></option>
                {sectors.map((sector, index) => (
                  <option key={index} value={sector} label={sector}></option>
                ))}
              </select>
              <label className={styles.label}>Teams</label>
              <select
                id="teams"
                className={styles.field}
                name="teams"
                onChange={handleInput}
                value={registerInput.teams}
              >
                <option value="" label="Select"></option>
                {teams.map(team => (
                  <option key={team.id} value={team.teams} label={team.teams}></option> // Adjust keys as per your data structure
                ))}
              </select>
              {/* <TextInput
              className={styles.field}
              label="Company "
              name="company"
              type="text"
              required
            /> */}
              <TextInput
                className={styles.field}
                label=" Expertise"
                name="expertise"
                type="text"
                required
                onChange={handleInput}
                value={registerInput.expertise}
              />
              {/* <Editor
              state={content}
              onChange={setContent}
              classEditor={styles.editor}
              label="Bio"
              tooltip="Description"
              name="bio"
              // onChange={handleInput}
              value={registerInput.bio}
            /> */}
            </div>
          </Item>

          <DivCard
            className={`${styles.card} ${styles.card2}`}
            title="Login"
            classTitle={cn("title-purple", styles.title)}
            classCardHead={styles.head}
          >
            <div className={styles.inputrow}>
              <TextInput
                className={`${styles.field} ${styles.rowfield}`}
                label="Email "
                name="email"
                type="email"
                required
                onChange={handleInput}
                value={registerInput.email}
              />
              <TextInput
                className={`${styles.field} ${styles.rowfield}`}
                label="Password"
                name="password"
                type="password"
                required
                onChange={handleInput}
                value={registerInput.password}
              />
            </div>
            {loading ?
              <button className={cn("button", styles.button)}>Add new user</button>
              :
              <button disabled className={cn("button", styles.button)}>Loading...</button>}
          </DivCard>
        </div>
      </div>
    </form>
  );
};

export default Account;
