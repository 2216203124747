import React, { useState } from "react";
import axios from 'axios';
import styles from "./Sidebar.module.sass";
import { Link, NavLink, useLocation ,useNavigate } from "react-router-dom";
import cn from "classnames";
import Icon from "../Icon";
import Theme from "../Theme";
import Dropdown from "./Dropdown";
import Image from "../Image";

const navigation = [
  {
    title: "Home",
    icon: "home",
    url: "/",
  },

  {
    title: "Users",
    icon: "user",
    url: "/users",
  },

  {
    title: "Individual",
    icon: "user",
    url: "/individual",
  },

  {
    title: "Companies",
    icon: "company",
    url: "/companies",
  },

  {
    title: "Shareinfo",
    icon: "share",
    url: "/shareinfo",
  },

  // {
  //   title: "Scanned",
  //   icon: "scan",
  //   url: "/scanned",
  // },

  {
    title: "All Connection",
    icon: "allconnection",
    url: "/connection",
  },

  {
    title: "Archive",
    icon: "archive",
    url: "/archive",
  },

  {
    title: "Sales",
    icon: "sales",
    url: "/Adminsales",
  },

  {
    title: "Account",
    icon: "account",
    url: "/account",
  },

  
];

const Sidebar = ({ className, onClose }) => {
  // const [visibleHelp, setVisibleHelp] = useState(false);
  const [visible, setVisible] = useState(false);

  const { pathname } = useLocation();
  const history = useNavigate();
  const logoutsubmit = (e) => {
    e.preventDefault();
    axios.get("/sanctum/csrf-cookie").then((response) => {
    axios.post('/api/user/logout')
    .then(res => {
        if (res.status === 200) {
            localStorage.removeItem('auth_token');
            localStorage.removeItem('auth_role');
            localStorage.removeItem('id');
            localStorage.removeItem('name');
            localStorage.removeItem('company_id');
            localStorage.removeItem('expiration_time');
            history('/signin');
            // <Navigate to='/'/>
        }
    })
    .catch(error => {
        console.error("Logout failed:", error);
    });
  });
}

  return (
    <>
    
      <div
        className={cn(styles.sidebar, className, {
          [styles.active]: visible,
        })}
      >
        
        <button className={styles.close} onClick={onClose}>
          <Icon name="close" size="24" />
        </button>
        <Link className={styles.logo} to="/" onClick={onClose}>
          <Image
            className={styles.pic}
            src="/images/logo-dark.png"
            srcDark="/images/logo-light.png"
            alt="Core"
          />
        </Link>
        <div className={styles.menu}>
          {navigation.map((x, index) =>
            x.url ? (
              <NavLink
                className={cn(styles.item, {
                  [styles.active]: pathname === x.url,
                })}
                to={x.url}
                key={index}
                onClick={onClose}
              >
                <Icon name={x.icon} size="24" />
                {x.title}
              </NavLink>
            ) : (
              <Dropdown
                className={styles.dropdown}
                visibleSidebar={visible}
                setValue={setVisible}
                key={index}
                item={x}
                onClose={onClose}
              />
            )
          )}
        </div>
        <button  className={`${styles.item} ${styles.logout}`} type='button' onClick={logoutsubmit}>
        <Icon name="logout" size="24" />logout</button>
        <button className={styles.toggle} onClick={() => setVisible(!visible)}>
          <Icon name="arrow-right" size="24" />
          <Icon name="close" size="24" />
        </button>
        <div className={styles.foot}>
          {/* <button className={styles.link} onClick={() => setVisibleHelp(true)}>
            <Icon name="help" size="24" />
            Help & getting started
            <div className={styles.counter}>8</div>
          </button> */}
          <Theme className={styles.theme} visibleSidebar={visible} />
        </div>
      </div>
      {/* <Help
        visible={visibleHelp}
        setVisible={setVisibleHelp}
        onClose={onClose}
      /> */}
      <div
        className={cn(styles.overlay, { [styles.active]: visible })}
        onClick={() => setVisible(false)}
      ></div>
    </>
  );
};

export default Sidebar;
