import React, { useState } from "react";
import styles from "./Row.module.sass";
import ModalProduct from "../../../../../components/ModalProduct";
import { Link } from "react-router-dom";


const Row = ({ item, value, onChange, up }) => {
  const [visibleActions, setVisibleActions] = useState(false);
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);

  return (
    <>
      <div className={styles.row} onMouseLeave={() => setVisibleActions(false)}>
        <div className={styles.col}>
          <div className={styles.user}>
            <div className={styles.avatar}>
              <img src={`http://daleelnfc.com/storage/${item.company_info.profile.image}`} alt="Avatar" />
            </div>
            <div className={styles.details}>
              <div className={styles.man}>
                <div className={styles.label}>Name</div>
                {item.company_info.profile.name}</div>
              {/* <div className={styles.login}>
              <div className={styles.label}>Sector</div>
                {item.company_info.profile.sector}</div> */}
            </div>
          </div>
        </div>
        <div className={styles.col}>
        <div className={styles.label}>Email</div>
          {item.company_info.email}
        </div>
        <div className={styles.col}>
        <div className={styles.label}>Users</div>
          {item.num_users}</div>
        <div className={styles.col}>
        <div className={styles.label}>Cards</div>
          {item.num_cards}</div>
        <div className={styles.col}>
        <div className={styles.label}>Sales</div>
          {item.seller_name}</div>
        <div className={styles.col}>
        <div className={styles.label}>List of employees</div>
        <Link to={`/sales/sales/companies/list/${item.id}`} className={styles.link}> List of employees</Link></div>
      </div>
      <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
    </>
  );
};

export default Row;
