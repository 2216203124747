import React, { useEffect, useState } from "react";
import styles from "./EditLink.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import TextInput from "../../../components/TextInput";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { Switch } from "antd";
const EditLink = () => {
  const [linksInput, setLinks] = useState([]);
  const [error, setError] = useState([]);
  const { id } = useParams();
  const history = useNavigate();

  const handleInput = (e) => {
    setLinks({ ...linksInput, [e.target.name]: e.target.value });
  };

  const [switchPrimary, setswitchPrimary] = useState(linksInput.primary);
  const [switchHide, setswitchHide] = useState(linksInput.hide);
  
  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.get(`api/viewLink/${id}`).then((res) => {
        if (res.data.status === 200) {
          setLinks(res.data.data);
          
        } else if (res.data.status === 404) {
          swal("Oops!", res.data.message, "error");
          history(-1);
        }
      });
    });
  }, [id]);

  

  const UpdateLink = (e) => {
    e.preventDefault();

    const data = linksInput;
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.put(`api/updateLink/${id}`, data).then((res) => {
        if (res.data.status === 200) {
          history(-1);
          setError([]);
          swal("Success!", "Link Edited Successfully!", "success");
        } else if (res.data.status === 422) {
          setError(res.data.errors);
          swal("Oops!", "Fields is Required", "error");
        }
      });
    });
  };

  const deleteLink = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this Link!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Delete!",
      cancelButtonText: "Cancel",
      closeOnConfirm: false,
      closeOnCancel: false,
    }).then((result) => {
      if (result.isConfirmed) {
        // If user confirms deletion, proceed with deletion
        axios.get("/sanctum/csrf-cookie").then((response) => {
          axios.delete(`api/destroyLink/${id}`).then((res) => {
            if (res.data.status === 200) {
              // Display success message
              Swal.fire("Deleted!", "Link has been deleted.", "success");
              history(-1);
            }
          });
        });
      } else {
        // If user cancels, show cancellation message
        Swal.fire("Cancelled", "Your Link is safe :)", "error");
      }
    });
  };
  return (
    <div>
      <Card
        onSubmit={UpdateLink}
        className={styles.card}
        title="Edit New Link"
        classTitle={cn("title-purple", styles.title)}
        classCardHead={styles.head}
      >
        <label className={styles.label}>Type</label>

        <select
          id="Sector"
          className={styles.field}
          name="type"
          required
          onChange={handleInput}
          value={linksInput.type}
        >
          <option value="" label="select"></option>
          <option value="facebook" label="Facebook">
            {" "}
            Facebook
          </option>
          <option value="instagram" label="Instagram">
            {" "}
            Instagram
          </option>
          <option value="threads" label="Threads">
            {" "}
            Threads
          </option>
          <option value="sky" label="Skype">
            {" "}
            Skype
          </option>
          <option value="youtube" label="Youtube">
            {" "}
            Youtube
          </option>
          <option value="linkedin" label="Linkedin">
            Linkedin
          </option>
          <option value="reddit" label="Reddit">
            {" "}
            Reddit
          </option>
          <option value="snapchat" label="Snapchat">
            Snapchat
          </option>
          <option value="tiktok" label="Tiktok">
            {" "}
            Tiktok
          </option>
          <option value="x" label="X">
            {" "}
            X
          </option>
        </select>
        <h2>{error.logo}</h2>
        <TextInput
          className={styles.field}
          label="Link Name "
          name="name"
          type="text"
          required
          value={linksInput.name}
          onChange={handleInput}
        />
        <h2>{error.name}</h2>
        <TextInput
          className={styles.field}
          label="URL "
          name="url"
          type="text"
          required
          value={linksInput.url}
          onChange={handleInput}
        />
        <h2>{error.url}</h2>


        <div className={styles.col}>
          <div className={styles.label}>Primary</div>
          {console.log(switchHide)}

          <Switch
            defaultChecked={linksInput.primary}

          />

          <div className={styles.label}>Hide</div>
          {console.log(switchPrimary)}
          <Switch
            defaultChecked={switchPrimary}
            name="hide"
          />
        </div> 



        <button type="submit" className={cn("button", styles.button)}>
          Save
        </button>
        <button
          onClick={deleteLink}
          className={cn("button", styles.button, styles.custombutton)}
        >
          Delete
        </button>
      </Card>
    </div>
  );
};

export default EditLink;
