import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import axios from "axios";
import cn from "classnames";
import styles from "./Shop.module.sass";
import Profile from "./Profile";
import MyLinks from "./MyLinks";
import Connections from "../../Connection";
import ShareInfo from "../../ShareInfo";
import ScannedCard from "../../ScanCard";
import DivCard from "../../../../components/CardDiv";



const navigation = [
  "My Links",
  "Connections",
  "Share Info",
  // "Scanned Card",
  // "All",
];
const intervals = ["Most recent", "Most new", "Most popular"];

const Shop = () => {
  const { id } = useParams();
  const [activeIndex, setActiveIndex] = useState(0);
  const [sorting, setSorting] = useState(intervals[0]);
  const [links, setLinks] = useState([]);
  const [share, setShare] = useState([]);
  const [connections, setConnections] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    axios.get(`api/GetUserProfile/${id}`).then((res) => {
      if (res.data.status === 200) {
        setLinks(res.data.data);
      }
      setloading(false);
    });
  }, [id]);

  useEffect(() => {
    axios.get(`api/GetuserShareInfo/${id}`).then((res) => {
      if (res.data.status === 200) {
        setShare(res.data.data);
      }
      setloading(false);
    });
  }, [id]);

  useEffect(() => {
    axios.get(`api/ViewUserFollow/${id}`).then((res) => {
      if (res.data.status === 200) {
        setConnections(res.data.data);
      }
      setloading(false);

    });
  }, [id]);

  return (
    <>
      {loading ? (
        <div className={styles.shop}>
          <div className={styles.background}>
            <img src="/images/content/bg-shop.jpg" alt="Background" />
          </div>
          <DivCard className={styles.card}>
            <center>
              <ScaleLoader color="#8E59FF" />
            </center>
          </DivCard>
        </div>
      ) : (
        <div className={styles.shop}>
          {links.map((x, index) => (
            <div key={index} className={styles.background}>
              {
                x.banner ? <img src={`https://daleelnfc.com/storage/${x.banner}`} alt="Avatar" />
                  :
                  <img src="/images/content/bg-shop.png" alt="Avatar" />
              }
            </div>
          ))}

          <DivCard className={styles.card}>
            <Profile Links={links} />
            <div className={styles.control}>
              <div className={styles.nav}>
                {navigation.map((x, index) => (
                  <button
                    className={cn(styles.link, {
                      [styles.active]: index === activeIndex,
                    })}
                    onClick={() => setActiveIndex(index)}
                    key={index}>
                    {x}
                  </button>
                ))}
              </div>
              {/* <div className={styles.dropdownBox}>
                <Link
                  to="/shop/editlink"
                  className={cn("button", styles.button)}> Extract
                </Link>
              </div> */}
            </div>
            <div className={styles.wrap}>
              {activeIndex === 0 && <MyLinks Links={links} id={id} />}
              {activeIndex === 1 && (
                <>
                  <div className={styles.followers}>
                    <Connections connections={connections} />
                  </div>
                </>
              )}
              {activeIndex === 2 && (
                <>
                  <div className={styles.followers}>
                    <ShareInfo share={share} />
                  </div>
                </>
              )}
              {/* {activeIndex === 3 && (
                <>
                  <div className={styles.followers}>
                    <ScannedCard />
                  </div>
                </>
              )} */}
              {/* {activeIndex === 4 && (
                <>
                  <div className={styles.followers}>
                    <Connections />
                    <ShareInfo />
                    <ScannedCard />
                  </div>
                </>
              )} */}
            </div>
          </DivCard>
        </div>
      )}
    </>
  );
};
export default Shop;