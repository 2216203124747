import React, { useState } from "react";
import cn from "classnames";
import styles from "./PayoutHistory.module.sass";
import Card from "../../../components/Card";
import Form from "../../../components/Form";
import { Link } from "react-router-dom";
import { ScaleLoader } from "react-spinners";

const PayoutHistory = ({ items, loading }) => {
  const getStatusColorClass = (status) => {
    switch (status) {
      case "shipped":
        return styles.shipped;
      case "approved":
        return styles.approved;
      case "ready to ship":
        return styles.ready;
      case "under progress":
        return styles.under;
      case "delivered":
        return styles.delivered;
      default:
        return "";
    }
  };
  // SEARCING
  const [search, setSearch] = useState("");

  return (
    <Card
      className={styles.card}
      title="Sales"
      classTitle={cn("title-purple", styles.title)}
      classCardHead={styles.head}
      head={
        <>
          <Form
            className={styles.form}
            value={search}
            setValue={setSearch}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search user , sector ..."
            type="text"
            name="search"
            icon="search"
          />
        </>
      }
    >
      {loading ? (
        <center>
          <ScaleLoader color="#8E59FF" />
        </center>
      ) : items.length === 0 ? (
        <center>No Sales</center>
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.table}>
            <div className={`${styles.row} ${styles.rowtable}`}>
              <div className={styles.col}>For</div>
              <div className={styles.col}>Type</div>
              <div className={styles.col}>Status</div>
              <div className={styles.col}>Number of cards</div>
              <div className={styles.col}>Earnings</div>
              <div className={styles.col}>Transaction reference</div>
              <div className={styles.col}>Details</div>
            </div>
            {items
              .filter((item) => {
                return search.toLowerCase() === ""
                  ? item
                  : (item.name.toLowerCase().includes(search) ||
                      item.reference.toLowerCase().includes(search)) === null
                  ? ""
                  : item.name.toLowerCase().includes(search) ||
                    item.reference.toLowerCase().includes(search);
              })
              .map((x, index) => (
                <div className={styles.row} key={index}>
                  <div className={styles.col}>
                    <div className={styles.label}>Name</div>
                    {x.name}
                  </div>
                  <div className={cn(styles.col)}>
                    <div className={styles.label}>Type</div>
                    {x.type}
                  </div>
                  <div className={cn(styles.col)}>
                    <div className={styles.label}>Status</div>
                    <span className={getStatusColorClass(x.status)}>
                      {x.status}
                    </span>
                  </div>
                  <div className={styles.col}>
                    <div className={styles.label}>No of cards</div>
                    {x.num_card}
                  </div>
                  <div className={styles.col}>
                    <div className={styles.label}>Earnings</div>
                    {x.earnings}
                  </div>
                  <div className={styles.col}>
                    <div className={styles.label}>Reference</div>
                    {x.reference}
                  </div>
                  <div className={styles.col}>
                    <div className={styles.label}>Details</div>
                    <Link
                      to={`/finance/finance/details/${x.id}`}
                      className={styles.detailsLink}
                    >
                      Details
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </Card>
  );
};

export default PayoutHistory;
