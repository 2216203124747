import React, { useState } from "react";
import styles from "./Market.module.sass";
import Row from "./Row";
import Icon from "../../../../../components/Icon";

const Market = ({ items }) => {
  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  return (
    <div className={styles.market}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>Name</div>

          <div className={styles.col}>Email</div>
          <div className={styles.col}>Phone Number</div>
          <div className={styles.col}>Companies</div>
        </div>
        {items.map((user) =>
          {
            return (
              <Row
                item={user}
                key={user.id}
                up={user.length - user <= 2}
                value={selectedFilters.includes(user.id)}
                onChange={() => handleChange(user.id)}
              />
            );
          }
        )}
      </div>
      <div className={styles.foot}>
        <button className={styles.arrow}>
          <Icon name="arrow-left" size="20" />
        </button>
        <button className={styles.arrow}>
          <Icon name="arrow-right" size="20" />
        </button>
      </div>
    </div>
  );
};

export default Market;
