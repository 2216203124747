import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import PageSales from "./components/PageSales";
import PageCoordinator from "./components/PageCoordinator";
import PageLogistics from "./components/PageLogistics";
import PageFinance from "./components/PageFinance";
import Home from "./screens/Home";
import ProductsDashboard from "./screens/ProductsDashboard";
import EditAdminAccount from "./screens/Account/AccountEdit";
import Approved from "./screens/Sales/Details/Approved";
import SignIn from "./screens/SignIn";
import RestPassword from "./screens/RestPassword";
import Archive from "./screens/Archive";
import Shop from "./screens/Shop";
import Shareshop from "./screens/Shop/ShareInfo";
import Connectionshop from "./screens/Shop/Connection";
import PageList from "./screens/PageList";
import Users from "./screens/Users";
import EditUser from "./screens/Users/EditUser"
import Individual from "./screens/Individual";
import DetailsIndividual from "./screens/Individual/Details";
import Connection from "./screens/Connection";
import NewLinks from "./screens/Shop/NewLinks";
import EditLink from "./screens/Shop/EditLink";
import Sales from "./screens/Sales";
import Account from "./screens/Account";
import Companies from "./screens/Companies";
import ShareInfo from "./screens/ShareInfo";
import Scanned from "./screens/ScanCard";
import Details from "./screens/Individual/Details";
import AddCard from "./screens/Individual/AddCard";
import AddCompany from "./screens/Companies/AddCompany";
import ListAccount from "./screens/Companies/ListAccount";
import DetailsListCompany from "./screens/Companies/ListAccount/Details";
import MainProfile from "./screens/MainProfile";
import AddNewAccount from "./screens/Account/AddNewAccount";

import SalesHome from "./screens/SalesPage/Home";
import SalesHomeDetails from "./screens/SalesPage/Home/Details";
import SalesIndividual from "./screens/SalesPage/Individual";
import SalesIndividualDetails from "./screens/SalesPage/Individual/Details";
import AddNewOrder from "./screens/SalesPage/Individual/AddNewOrder";
import SalesCompanies from "./screens/SalesPage/Companies";
import SalesListAccount from "./screens/SalesPage/Companies/ListAccount";
import SalesListDetails from "./screens/SalesPage/Companies/ListAccount/Details";

import CoordinatorHome from "./screens/CoordinatorPage/Home";
import CoordinatorHomeDetails from "./screens/CoordinatorPage/Home/Details";

import LogisticsHome from "./screens/LogisticsPage";
import LogisticsHomeDetails from "./screens/LogisticsPage/Details";

import FinanceHome from "./screens/FinancePage";
import FinanceHomeDetails from "./screens/FinancePage/Details";
import "./config";
import PrivateRoute from "./AdminRoutes";
import Page403 from "./screens/Errors/403";
import Page404 from "./screens/Errors/404";
import CompanyRoutes from "./CompanyRoutes";

// Company
import PageCompany from "./components/PageCompany";
import Company from "./screens/CompanyPage/Home/ProductsDashboard";
import AddAccount from "./screens/CompanyPage/Home/AddAccount";
import EditAccount from "./screens/CompanyPage/Home/AccountEdit";
import PersonalAccount from "./screens/CompanyPage/Home/Account";
import CompanyNewLinks from "./screens/CompanyPage/AddNewLinks";
import CompanyEditLinks from "./screens/CompanyPage/EditLink";
import CompanyArchive from "./screens/CompanyPage/Archive";
import Following from "./screens/CompanyPage/Following";

import CompanyScanned from "./screens/CompanyPage/ScanCard";
import CompanyShareInfo from "./screens/CompanyPage/ShareInfo";
import CompanyConnection from "./screens/CompanyPage/Connection";

import CompanyMainScanned from "./screens/CompanyPage/MainScanned";
import CompanyMainShareInfo from "./screens/CompanyPage/MainShareinfo";
import CompanyMainConnection from "./screens/CompanyPage/MainAllConnection";

import CompanyTeamleader from "./screens/CompanyPage/TeamLeader";
import AddTeamleader from "./screens/CompanyPage/TeamLeader/AddTeamleader";
import LogisticsRoutes from "./LogisticsRoutes";
import CoordinatorRoutes from "./CoordinatorRoutes";
import FinanceRoutes from "./FinanceRoutes";
import SalesRoutes from "./SalesRoutes";
import AddCompanySales from "./screens/SalesPage/Companies/AddCompany";

//team leader
import PageTeamleader from "./components/PageTeamleader";
import Teamleader from "./screens/TeamleaderPage/Home/ProductsDashboard";
import AddAccountTeamleader from "./screens/TeamleaderPage/Home/AddAccount";
import EditAccountTeamleader from "./screens/TeamleaderPage/Home/AccountEdit";
import PersonalAccountTeamleader from "./screens/TeamleaderPage/Home/Account";
import TeamleaderNewLinks from "./screens/TeamleaderPage/AddNewLinks";
import TeamleaderEditLinks from "./screens/TeamleaderPage/EditLink";
import FollowingTeamleader from "./screens/TeamleaderPage/Following";
import TeamleaderScanned from "./screens/TeamleaderPage/ScanCard";
import TeamleaderShareInfo from "./screens/TeamleaderPage/ShareInfo";
import TeamleaderConnection from "./screens/TeamleaderPage/Connection";
import TeamleaderMainScanned from "./screens/TeamleaderPage/MainScanned";
import TeamleaderMainShareInfo from "./screens/TeamleaderPage/MainShareinfo";
import TeamleaderMainConnection from "./screens/TeamleaderPage/MainAllConnection";

// Sales Page
import SalesShop from "./screens/SalesPage/Shop";

import SalesNewLinks from "./screens/SalesPage/Shop/NewLinks";
import TeamleaderRoutes from "./TeamLeaderRoutes";

import Forget from "./screens/forgetpassword"
import { useEffect } from "react";
import swal from "sweetalert";

function App() {
  const authToken = localStorage.getItem("auth_token");
  const authRole = localStorage.getItem("auth_role");

  const history = useNavigate();
  const routes = [
    {
      role: "admin",
      route: "/",
    },
    {
      role: "company",
      route: "/company",
    },
    {
      role: "logis",
      route: "/logis",
    },
    {
      role: "coor",
      route: "/coor",
    },
    {
      role: "finance",
      route: "/finance",
    },
    {
      role: "sales",
      route: "/sales",
    },
    {
      role: "Teamleader",
      route: "/Teamleader",
    },
  ];

  const userRoute = routes.find((route) => route.role === authRole);

  const navigate = useNavigate();

  const isSessionExpired = () => {
    const currentTime = new Date().getTime();
    const expirationTime = localStorage.getItem("expiration_time");
    return currentTime > expirationTime;
  };
  // console.log(isSessionExpired());
  // Check if session has expired and clear session data if necessary
  const handleSessionExpiration = () => {
    if (isSessionExpired()) {
      localStorage.removeItem("auth_token");
      localStorage.removeItem("auth_role");
      localStorage.removeItem("id");
      localStorage.removeItem("name");
      localStorage.removeItem("company_id");
      localStorage.removeItem("expiration_time");
      // Redirect user to login page or show session expired message
      // swal('Warning','Your session expired! Please Login Again','warning')
      navigate('/signin')
    }
    // alert("Session expired. Please log in again.");
  };
  
  useEffect(() => {
    if (authToken && authRole) {

      const interval = setInterval(() => {
        handleSessionExpiration();
      }, 1000); // Check every second (adjust as necessary)

      return () => clearInterval(interval); // Cleanup interval on component unmount
    }
  }, []);
  return (
    <Routes>
      <Route path="resetpassword" element={<RestPassword />} />
      <Route path="forgetpassword" element={<Forget />} />
      <Route
        exact
        path="signin"
        element={
          authToken && userRoute ? (
            <Navigate to={userRoute.route} />
          ) : (
            <SignIn />
          )
        }
      />
      <Route path="/403" element={<Page403 />} />
      <Route path="/404" element={<Page404 />} />
      <Route path="*" element={<Page404 />} />

      <Route path="ViewProfile/:id" element={<MainProfile />} />
      <Route path="/" element={<PrivateRoute />}>
        <Route
          index
          element={
            <Page title="Dashboard">
              <ProductsDashboard />
            </Page>
          }
        />
        <Route
          path="products/dashboard"
          element={
            <Page title="Products dashboard">
              <Home />
            </Page>
          }
        />

        <Route
          path="/company-details/:id"
          element={
            <Page>
              <DetailsListCompany />
            </Page>
          }
        />

        <Route
          path="companies"
          element={
            <Page>
              <Companies />
            </Page>
          }
        />

        <Route
          path="/list-account/:id"
          element={
            <Page>
              <ListAccount />
            </Page>
          }
        />

        <Route
          path="addnewaccount"
          element={
            <Page>
              <AddNewAccount />
            </Page>
          }
        />

        <Route
          path="addcompany"
          element={
            <Page>
              <AddCompany />
            </Page>
          }
        />

        <Route
          path="addcard"
          element={
            <Page>
              <AddCard />
            </Page>
          }
        />

        <Route
          path="details"
          element={
            <Page>
              <Details />
            </Page>
          }
        />

        <Route
          path="shop/newlinks/:id"
          element={
            <Page>
              <NewLinks />
            </Page>
          }
        />

        <Route
          path="shop/:id"
          element={
            <Page wide>
              <Shop />
            </Page>
          }
        />

        <Route
          path="shareshop"
          element={
            <Page wide>
              <Shareshop />
            </Page>
          }
        />
        <Route
          path="connectionshop"
          element={
            <Page wide>
              <Connectionshop />
            </Page>
          }
        />

        <Route
          path="/individual-details/:id"
          element={
            <Page>
              <DetailsIndividual />
            </Page>
          }
        />



        <Route
          path="account"
          element={
            <Page>
              <Account />
            </Page>
          }
        />

        <Route
          path="shop/editlink/:id"
          exact
          element={
            <Page>
              <EditLink />
            </Page>
          }
        />
        <Route
          path="EditAdminAccount/:id"
          element={
            <Page>
              <EditAdminAccount />
            </Page>
          }
        />
        <Route
          path="account"
          element={
            <Page title="Account">
              <Account />
            </Page>
          }
        />

        <Route
          path="users"
          element={
            <Page>
              <Users />
            </Page>
          }
        />
        <Route
          path="/EditUser/:id"
          element={
            <Page>
              <EditUser />
            </Page>
          }
        />

        <Route
          path="individual"
          element={
            <Page>
              <Individual />
            </Page>
          }
        />

        <Route
          path="connection"
          element={
            <Page>
              <Connection />
            </Page>
          }
        />
        <Route
          path="shareinfo"
          element={
            <Page>
              <ShareInfo />
            </Page>
          }
        />
        <Route
          path="scanned"
          element={
            <Page>
              <Scanned />
            </Page>
          }
        />
        <Route
          path="archive"
          element={
            <Page>
              <Archive />
            </Page>
          }
        />
        <Route
          path="sales-approved/:id"
          element={
            <Page>
              <Approved />
            </Page>
          }
        />
        <Route
          path="Adminsales"
          element={
            <Page>
              <Sales />
            </Page>
          }
        />

        <Route path="reset-password" element={<RestPassword />} />
        <Route path="pagelist" element={<PageList />} />
      </Route>

      <Route path="/company" element={<CompanyRoutes />}>
        <Route
          path=""
          element={
            <PageCompany title="">
              <Company />
            </PageCompany>
          }
        />
        <Route
          path="account-add"
          element={
            <PageCompany>
              <AddAccount />
            </PageCompany>
          }
        />
        <Route
          path="account-edit"
          element={
            <PageCompany>
              <EditAccount />
            </PageCompany>
          }
        />
        <Route
          path="personal-account/:id"
          element={
            <PageCompany>
              <PersonalAccount />
            </PageCompany>
          }
        />
        <Route
          path="company-newlinks/:id"
          element={
            <PageCompany>
              <CompanyNewLinks />
            </PageCompany>
          }
        />
        <Route
          path="company-editlinks/:id"
          element={
            <PageCompany>
              <CompanyEditLinks />
            </PageCompany>
          }
        />
        <Route
          path="company-archive"
          element={
            <PageCompany>
              <CompanyArchive />
            </PageCompany>
          }
        />
        <Route
          path="company-following"
          element={
            <PageCompany>
              <Following />
            </PageCompany>
          }
        />
        <Route
          path="company-connection"
          element={
            <PageCompany>
              <CompanyConnection />
            </PageCompany>
          }
        />

        <Route
          path="company-shareinfo"
          element={
            <PageCompany>
              <CompanyShareInfo />
            </PageCompany>
          }
        />
        <Route
          path="/company/EditCompanyUser/:id"
          element={
            <PageCompany>
              <EditUser />
            </PageCompany>
          }
        />

        <Route
          path="company-scanned"
          element={
            <PageCompany>
              <CompanyScanned />
            </PageCompany>
          }
        />

        <Route
          path="main-scanned"
          element={
            <PageCompany>
              <CompanyMainScanned />
            </PageCompany>
          }
        />

        <Route
          path="main-shareinfo"
          element={
            <PageCompany>
              <CompanyMainShareInfo />
            </PageCompany>
          }
        />
        <Route
          path="main-connection"
          element={
            <PageCompany>
              <CompanyMainConnection />
            </PageCompany>
          }
        />
        <Route
          path="company-teamleader"
          element={
            <PageCompany>
              <CompanyTeamleader />
            </PageCompany>
          }
        />

        <Route
          path="addteamleader"
          element={
            <PageCompany>
              <AddTeamleader />
            </PageCompany>
          }
        />
      </Route>

      <Route path="/logis" element={<LogisticsRoutes />}>
        <Route
          path=""
          element={
            <PageLogistics>
              <LogisticsHome />
            </PageLogistics>
          }
        />
        <Route
          path="logistics/details/:id"
          element={
            <PageLogistics>
              <LogisticsHomeDetails />
            </PageLogistics>
          }
        />
      </Route>
      <Route path="/coor" element={<CoordinatorRoutes />}>
        <Route
          path=""
          element={
            <PageCoordinator>
              <CoordinatorHome />
            </PageCoordinator>
          }
        />
        <Route
          path="coordinator/details/:id"
          element={
            <PageCoordinator>
              <CoordinatorHomeDetails />
            </PageCoordinator>
          }
        />
      </Route>
      <Route path="/finance" element={<FinanceRoutes />}>
        <Route
          path=""
          element={
            <PageFinance>
              <FinanceHome />
            </PageFinance>
          }
        />
        <Route
          path="finance/details/:id"
          element={
            <PageFinance>
              <FinanceHomeDetails />
            </PageFinance>
          }
        />
      </Route>
      <Route path="/sales" element={<SalesRoutes />}>
        <Route
          path=""
          element={
            <PageSales>
              <SalesHome />
            </PageSales>
          }
        />
        <Route
          path="sales/details/:id"
          element={
            <PageSales>
              <SalesHomeDetails />
            </PageSales>
          }
        />

        <Route
          path="sales/individual"
          element={
            <PageSales>
              <SalesIndividual />
            </PageSales>
          }
        />
        <Route
          path="sales/individual/details/:id"
          element={
            <PageSales>
              <SalesIndividualDetails />
            </PageSales>
          }
        />
        <Route
          path="sales/individual/order"
          element={
            <PageSales>
              <AddNewOrder />
            </PageSales>
          }
        />

        <Route
          path="sales/companies"
          element={
            <PageSales>
              <SalesCompanies />
            </PageSales>
          }
        />

        <Route
          path="sales/companies/list/:id"
          element={
            <PageSales>
              <SalesListAccount />
            </PageSales>
          }
        />
        <Route
          path="sales/companies/userprofile/:id"
          element={
            <PageSales>
              <SalesShop />
            </PageSales>
          }
        />
        <Route
          path="sales/shop/newlinks/:id"
          element={
            <PageSales>
              <SalesNewLinks />
            </PageSales>
          }
        />
        <Route
          path="details/:id"
          element={
            <PageSales>
              <SalesListDetails />
            </PageSales>
          }
        />
        <Route
          path="addcompanysales"
          element={
            <PageSales>
              <AddCompanySales />
            </PageSales>
          }
        />
      </Route>

      <Route path="/Teamleader" element={<TeamleaderRoutes />}>
        <Route
          path=""
          element={
            <PageTeamleader>
              <Teamleader />
            </PageTeamleader>
          }
        />

        <Route
          path="addaccount-teamleader"
          element={
            <PageTeamleader>
              <AddAccountTeamleader />
            </PageTeamleader>
          }
        />

        <Route
          path="editaccount-teamleader"
          element={
            <PageTeamleader>
              <EditAccountTeamleader />
            </PageTeamleader>
          }
        />

        <Route
          path="personal-account-teamleader/:id"
          element={
            <PageTeamleader>
              <PersonalAccountTeamleader />
            </PageTeamleader>
          }
        />

        <Route
          path="newlinks-teamleader"
          element={
            <PageTeamleader>
              <TeamleaderNewLinks />
            </PageTeamleader>
          }
        />

        <Route
          path="editlinks-teamleader/:id"
          element={
            <PageTeamleader>
              <TeamleaderEditLinks />
            </PageTeamleader>
          }
        />

        <Route
          path="user-teamleader"
          element={
            <PageTeamleader>
              <FollowingTeamleader />
            </PageTeamleader>
          }
        />
        <Route
          path="scanned-teamleader"
          element={
            <PageTeamleader>
              <TeamleaderScanned />
            </PageTeamleader>
          }
        />

        <Route
          path="shareinfo-teamleader"
          element={
            <PageTeamleader>
              <TeamleaderShareInfo />
            </PageTeamleader>
          }
        />

        <Route
          path="connection-teamleader"
          element={
            <PageTeamleader>
              <TeamleaderConnection />
            </PageTeamleader>
          }
        />

        <Route
          path="mainscanned-teamleader"
          element={
            <PageTeamleader>
              <TeamleaderMainScanned />
            </PageTeamleader>
          }
        />

        <Route
          path="mainshareinfo-teamleader"
          element={
            <PageTeamleader>
              <TeamleaderMainShareInfo />
            </PageTeamleader>
          }
        />

        <Route
          path="mainconnection-teamleader"
          element={
            <PageTeamleader>
              <TeamleaderMainConnection />
            </PageTeamleader>
          }
        />
      </Route>
    </Routes>
  );
}

export default App;
