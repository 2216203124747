import React, { useState } from "react";
import styles from "./AddNewOrder.module.sass";
import cn from "classnames";
import Card from "../../../../components/Card";
import TextInput from "../../../../components/TextInput";
import { useNavigate } from "react-router";
import axios from "axios";
import swal from "sweetalert";

const AddNewOrder = () => {
  const history = useNavigate();
  const id = localStorage.getItem('id')
  const username = localStorage.getItem('name')
  const [loading, setLoading] = useState(true)
  const [registerInput, setRegister] = useState({
    name: "",
    // email: "",
    num_card: "",
    earnings: "",
    reference: "",
    error_list: [],
    sucess: "",
  });

  const handleInput = (e) => {
    // e.presist();
    setRegister({ ...registerInput, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(false)
    // Create FormData object
    const formData = new FormData();
    formData.append("name", registerInput.name);
    // formData.append("email", registerInput.email);
    formData.append("seller_name", username);
    formData.append("num_card", registerInput.num_card);
    formData.append("earnings", registerInput.earnings);
    formData.append("reference", registerInput.reference);
    formData.append("user_id", id);

    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios
        .post("api/AddCard", formData, {
          withCredentials: true, // Include cookies with the request
          headers: {
            "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
            "X-Requested-With": "XMLHttpRequest",
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            setRegister({
              ...registerInput,
              sucess: res.data.message,
              error_list: [],
            });
            setLoading(true)
            swal("Success", res.data.message, "success");
            history('/sales/sales/individual')
          } else {
            setRegister({
              ...registerInput,
              error_list: res.data.validation_errors,
            });
          }
        })
        .catch((error) => {
          console.error("Error registering:", error);
          // Handle registration error
        });
    });
  };

  return (
    <Card
      onSubmit={handleSubmit}
      className={styles.card}
      title="Add New Card"
      classTitle={cn("title-purple", styles.title)}
      classCardHead={styles.head}
    >
      <TextInput
        className={styles.field}
        label="For *"
        name="name"
        type="text"
        required
        onChange={handleInput}
        value={registerInput.name}
      />
      <TextInput
        className={styles.field}
        label="Number of card *"
        name="num_card"
        type="number"
        required
        onChange={handleInput}
        value={registerInput.num_card}
      />
      <TextInput
        className={styles.field}
        label="Cost ( USD ) *"
        name="earnings"
        type="number"
        required
        onChange={handleInput}
        value={registerInput.earnings}
      />
      <TextInput
        className={styles.field}
        label="Transaction reference *"
        name="reference"
        type="text"
        required
        onChange={handleInput}
        value={registerInput.reference}
      />
      {loading ?
        <button type="submit" className={cn("button", styles.button)}>Add new Card</button>
        :
        <button type="submit" disabled className={cn("button", styles.button)}>Loading...</button>
      }
    </Card>
  );
};

export default AddNewOrder;
