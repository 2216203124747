import React, { useEffect, useState } from "react";
import styles from "./Connections.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Form from "../../../components/Form";
import { Link } from "react-router-dom";
import Market from "./Market";
import Table from "./Table";
import { RingLoader, ScaleLoader } from "react-spinners";
import DivCard from "../../../components/CardDiv";
import { traffic } from "../../../mocks/traffic";
import { viewers } from "../../../mocks/viewers";
import axios from "axios";
import jsPDF from "jspdf";

import Dropdown from "../../../components/Dropdown";
// Sorting
const intervals = [
  "A",
  "Z",
]

const Connections = () => {
  const navigation = ["Viewers"];
  const [activeTab, setActiveTab] = useState(navigation[0]);

  const id = localStorage.getItem('id');
  const cid = localStorage.getItem('company_id');

  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setloading] = useState(true);
  const [sortDirection, setSortDirection] = useState("asc"); // Default to ascending
  const [filter, setFilter] = useState("A"); // Default filter

  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.get(`api/allConnectionsTeams?id=${id}&cid=${cid}`).then(res => {
        if (res.data.status === 200) {
          setUsers(res.data.content);
        }
        setloading(false)
      });
    });
  }, [])

  const toggleSortDirection = () => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  // Function to handle filter change
  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };
  const sortData = () => {
    return users.slice().sort((a, b) => {
      const nameA = a.email?.toLowerCase();
      const nameB = b.email?.toLowerCase();
      if (filter === 'A') {
        return sortDirection === "asc" ? nameA?.localeCompare(nameB) : nameB?.localeCompare(nameA);
      } else if (filter === 'Z') {
        return sortDirection === "asc" ? nameB?.localeCompare(nameA) : nameA?.localeCompare(nameB);
      }
      return 0;
    });
  };

  const handleSubmit = (e) => {
    alert();
  };
  // Extract
  const handleExtract = (e) => {
    e.preventDefault(); // Prevent default form submission

    const doc = new jsPDF();
    const columns = [
      "Name",
      "Sector",
      "Email",
      "Phone Number",
      "From",
      "Companies",
    ];

    const data = sortData.map((user) => [
      user.name,
      user.sector,
      user.email,
      user.phone,
      user.from,
      user.company,
    ]);

    doc.autoTable({
      head: [columns],
      body: data,
    });

    doc.save("ShareInfo.pdf");
  };
  return (
    <Card
      className={styles.card}
      title="All Connection"
      classTitle={cn("title-purple", styles.title)}
      classCardHead={styles.head}
      head={
        <>
          <Form
            className={styles.form}
            value={search}
            setValue={setSearch}
            onSubmit={() => handleSubmit()}
            placeholder="Search..."
            type="text"
            name="search"
            icon="search"
          />

          <Dropdown
            className={`${styles.dropdown} ${styles.newStyle}`}
            classDropdownHead={styles.dropdownHead}
            value={filter}
            setValue={handleFilterChange}
            options={intervals}
            small
          />
          {/* <div className={styles.contacts}>
            <button className={cn("button", styles.button)} onClick={handleExtract}>
              Extract
            </button>
          </div> */}
        </>
      }
    >
      {/* Loading Bar */}
      {loading ? (
        <DivCard className={styles.card}>
          <center>
            <ScaleLoader color="#8E59FF" />
          </center>
        </DivCard>
      ) : (
        <div className={styles.products}>
          <div className={styles.wrapper}>
            {activeTab === navigation[0] && <Market items={sortData()} search={search} />}
            {activeTab === navigation[1] && (
              <Table
                title="Traffic source"
                items={traffic}
              />
            )}
            {activeTab === navigation[2] && (
              <Table title="Viewers" items={viewers} />
            )}
          </div>
        </div>
      )}
    </Card>
  );
};
export default Connections;
