import React, { useEffect, useState } from "react";
import styles from "./companies.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Form from "../../../components/Form";
import Market from "./Market";
import Table from "./Table";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import * as XLSX from 'xlsx';
// data
import { traffic } from "../../../mocks/traffic";
import { viewers } from "../../../mocks/viewers";

const Companies = () => {
  const navigation = ["Viewers"];
  const id = localStorage.getItem('id');

  const [activeTab, setActiveTab] = useState(navigation[0]);
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  // PDF FUNCTION
  const handleExtract = (event) => {
    event.preventDefault(); // Prevent default action of button click

    const data = users.map((c) => [
        c.company_info.profile.name,
        c.company_info.email,
        c.hold === 1 ? "Holded" : "Active",
        c.num_users,
        c.num_cards,
        c.seller_name,
    ]);

    const worksheetData = [
        ["Name", "Email", "Status", "Users", "Cards", "Seller Name"],
        ...data
    ];

    // Create a worksheet from the data
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // Create a workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Companies");

    // Generate and download the Excel file
    XLSX.writeFile(workbook, "companies.xlsx");
};

  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then(() => {
      axios.get(`api/FetchAllCompanies?id=${id}`).then(res => {
        if (res.status === 200) {
          setUsers(res.data.data);
        }
        setLoading(false);
      });
    });
  }, [id]);

  return (
    <Card
      className={styles.card}
      title="Companies"
      classTitle={cn("title-purple", styles.title)}
      classCardHead={styles.head}
      head={
        <>
          <Form
            className={styles.form}
            value={search}
            setValue={setSearch}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search Name, Email"
            type="text"
            name="search"
            icon="search"
          />
          <div className={styles.contacts}>
            <button className={cn("button", styles.button)} onClick={handleExtract}>
              Extract
            </button>
            <Link to="/sales/addcompanysales" className={cn("button", styles.button)}>
              Add New Company
            </Link>
          </div>
        </>
      }
    >
      <div className={styles.products}>
        <div className={styles.wrapper}>
          {activeTab === navigation[0] && <Market loading={loading} items={users} search={search} />}
          {activeTab === navigation[1] && (
            <Table
              title="Traffic source"
              items={traffic}
            />
          )}
          {activeTab === navigation[2] && (
            <Table title="Viewers" items={viewers} />
          )}
        </div>
      </div>
    </Card>
  );
};

export default Companies;
