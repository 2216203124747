import React, { useState } from "react";
import axios from "axios";
import cn from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Entry.module.sass";
import styless from "./SignIn.module.css";
import TextInput from "../../../components/TextInput";

const Entry = ({ onConfirm }) => {
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [errors, setErrors] = useState([]);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);
  const email = query.get('email');
  const expires = query.get('expires');
  const signature = query.get('signature');

  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handlePasswordConfirmationChange = (e) => setPasswordConfirmation(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    setStatus(null);
    setLoading(true);

    try {
      const response = await axios.post("/api/password/reset", {
        email,
        password,
        password_confirmation: passwordConfirmation,
        expires,
        signature,
      });

      setStatus(response.data.message);
      setTimeout(() => {
        navigate("/signin");
      }, 3000);
    } catch (error) {
      if (error.response && error.response.data) {
        setErrors(error.response.data.error);
      } else {
        setErrors(["An error occurred."]);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.entry}>
      <div className={styles.body}>
        <form onSubmit={handleSubmit}>
          <TextInput
            className={styles.field}
            name="password"
            type="password"
            placeholder="New Password"
            icon="lock"
            required
            value={password}
            onChange={handlePasswordChange}
          />
          <TextInput
            className={styles.field}
            name="password_confirmation"
            type="password"
            placeholder="Confirm Password"
            icon="lock"
            required
            value={passwordConfirmation}
            onChange={handlePasswordConfirmationChange}
          />

          {errors.length > 0 && (
            <div className={styles.errors}>
              {errors.map((error, index) => (
                <div key={index} className={styles.error}>
                  {error}
                </div>
              ))}
            </div>
          )}
          {status && <div className={styles.success}>{status}</div>}

          <button
            className={cn("button", styles.button)}
            type="submit"
            disabled={loading}
          >
            {loading ? "Loading..." : "Reset Password"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Entry;
