import React, { useState } from "react";
import axios from "axios";
import cn from "classnames";
import styles from "./Entry.module.sass";
import styless from "./SignIn.module.css";
import TextInput from "../../../components/TextInput";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Link, useNavigate } from "react-router-dom";

const contentStyle = {
  background: "#FCFCFC",
  padding: "24px 24px 48px 24px",
  borderRadius: "16px",
  width: "560px",
};
const overlayStyle = { background: "rgba(244, 244, 244, 0.9)" };
const arrowStyle = { color: "#000" };
const modal = { background: "#000" };
const closeOnDocumentClick = { background: "#000" };

const Entry = ({ onConfirm }) => {
  const history = useNavigate();
  const [loading, setLoading] = useState(true)
  const [loginInput, setLogin] = useState({
    email: "",
    password: "",
    blocked: "",
    error_list: {
      email: "",
      password: "",
      blocked: ""
    },
  });

  const handleInput = (e) => {
    setLogin({ ...loginInput, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(false)
    const data = {
      email: loginInput.email,
      password: loginInput.password,
    };

    axios
      .get("/sanctum/csrf-cookie")
      .then((response) => {
        axios
          .post("api/login", data, {
            withCredentials: true, // Include cookies with the request
            headers: {
              "X-Requested-With": "XMLHttpRequest",
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              localStorage.setItem("auth_token", res.data.token);
              localStorage.setItem("auth_role", res.data.role);
              localStorage.setItem("id", res.data.id);
              localStorage.setItem("name", res.data.name);
              localStorage.setItem("company_id", res.data.company_id);
              const expirationTime = new Date().getTime() + (2 * 60 * 60 * 1000); // 2 hours
              const start_time = new Date().getTime();
              // const expirationTime = new Date().getTime() + (1 * 60 * 1000);
              // localStorage.setItem("start_time", start_time);
              localStorage.setItem("expiration_time", expirationTime);





              switch (res.data.role) {
                case "admin":
                  history("/");
                  break;
                case "company":
                  history("/company");
                  break;
                case "sales":
                  history("/sales");
                  break;
                case "coor":
                  history("/coor");
                  break;
                case "logis":
                  history("/logis");
                  break;
                case "finance":
                  history("/finance");
                  break;
                case "Teamleader":
                  history("/Teamleader");
                  break;
                default:
                  break;
              }
              setLoading(true)
            } else if (res.data.status === 422) {
              setLogin({
                ...loginInput,
                error_list: res.data.errors,
              });
              setLoading(true)
            } else if (res.data.status === 420) {
              setLogin({
                ...loginInput,
                error_list: {
                  blocked: res.data.message
                }
              });
              setLoading(true)
            } else if (res.data.status === 401) {
              const errorMessage = res.data.message.toLowerCase();
              if (errorMessage.includes("email")) {
                setLogin({
                  ...loginInput,
                  error_list: {
                    email: "Email is not correct",
                    password: "",
                  },
                });
                setLoading(true)
              } else if (errorMessage.includes("password")) {
                setLogin({
                  ...loginInput,
                  error_list: {
                    email: "",
                    password: "Password is not correct",
                  },
                });
                setLoading(true)
              } else {
                setLogin({
                  ...loginInput,
                  error_list: {
                    email: "Email or password is not correct",
                    password: "",
                  },
                });
                setLoading(true)
              }
            } else {
              setLogin({
                ...loginInput,
                error_list: {
                  email: "Email or password is not correct",
                  password: "",
                },
              });
              setLoading(true)
            }
          })
          .catch((error) => {
            console.error("Error logging in:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching CSRF cookie:", error);
      });
  };

  return (
    <div className={styles.entry}>
      <form className={styles.body} onSubmit={handleSubmit}>
        <div className={styles.subtitle}>Sign In with Open account</div>
        <TextInput
          className={styles.field}
          name="email"
          type="email"
          placeholder="Your email"
          required
          icon="mail"
          onChange={handleInput}
          value={loginInput.email}
        />

        <TextInput
          className={styles.field}
          name="password"
          type="password"
          placeholder="Password"
          required
          icon="lock"
          onChange={handleInput}
          value={loginInput.password}
        />
        <span className={styles.error}>{loginInput.error_list.email}</span>
        <span className={styles.error}>{loginInput.error_list.password}</span>
        <span className={styles.error}>{loginInput.error_list.blocked}</span>
        <div className={styless.boxes}>
          <Link to="/forgetpassword" className={styless.forgetpassword}> Forget Password? </Link>
        </div>

        {loading ?
          <button className={cn("button", styles.button)} type="submit">
            Continue
          </button>
          :
          <button disabled className={cn("button", styles.button)} type="submit">
            Loading...
          </button>}
      </form>
    </div>
  );
};

export default Entry;
