import React, { useEffect, useState } from "react";
import jsPDF from "jspdf";
import styles from "./ListAccount.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Form from "../../../components/Form";
import Market from "./Market";
import Table from "./Table";

import { traffic } from "../../../mocks/traffic";
import { viewers } from "../../../mocks/viewers";

import axios from "axios";
import { RingLoader, ScaleLoader } from "react-spinners";
import DivCard from "../../../components/CardDiv";

import Dropdown from "../../../components/Dropdown";
// Sorting
const intervals = [
  "A",
  "Z",
];

const ListAccount = () => {
  const navigation = ["Viewers"];

  const [activeTab, setActiveTab] = useState(navigation[0]);
  const [search, setSearch] = useState("");
  const id = localStorage.getItem('id');
  const cid = localStorage.getItem('company_id');
  const [users, setUsers] = useState([]);
  const [loading, setloading] = useState(true);
  const [sortDirection, setSortDirection] = useState("asc"); // Default to ascending
  const [filter, setFilter] = useState("A"); // Default filter

  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.get(`api/fetchesFollowingTeamleader?id=${id}&cid=${cid}`).then(res => {
        if (res.status === 200) {
          setUsers(res.data.content);
        }
        setloading(false);
      });
    });
  }, [id, cid]);


  const toggleSortDirection = () => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  // Function to handle filter change
  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };
  const sortData = () => {
    return users.slice().sort((a, b) => {
      const nameA = a.following.name?.toLowerCase();
      const nameB = b.following.name?.toLowerCase();
      if (filter === 'A') {
        return sortDirection === "asc" ? nameA?.localeCompare(nameB) : nameB?.localeCompare(nameA);
      } else if (filter === 'Z') {
        return sortDirection === "asc" ? nameB?.localeCompare(nameA) : nameA?.localeCompare(nameB);
      }
      return 0;
    });
  };

  const handleExtract = (e) => {
    e.preventDefault(); // Prevent default form submission

    const doc = new jsPDF();
    const columns = [
      "Name",
      "Sector",
      "Email",
      "Phone Number",
      "Companies",
      "From",
    ];
    const data = users.map((user) => [
      user.following.name,
      user.following.sector,
      user.email,
      user.phone,
      user.following.company,
      user.from,
    ]);

    doc.autoTable({
      head: [columns],
      body: data,
    });

    doc.save("Following.pdf");
  };

  return (
    <Card
      className={styles.card}
      title="Following"
      classTitle={cn("title-purple", styles.title)}
      classCardHead={styles.head}
      head={
        <>
          <Form
            className={styles.form}
            value={search}
            setValue={setSearch}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search..."
            type="text"
            name="search"
            icon="search"
          />

          <Dropdown
            className={`${styles.dropdown} ${styles.newStyle}`}
            classDropdownHead={styles.dropdownHead}
            value={filter}
            setValue={handleFilterChange}
            options={intervals}
            small
          />

          <div className={styles.contacts}>
            {/* <button onClick={handleExtract} className={cn("button", styles.button)} type="button">
              Extract
            </button> */}
          </div>
        </>
      }>
      {loading ? (
        <DivCard className={styles.card}>
          <center>
            <ScaleLoader color="#8E59FF" />
          </center>
        </DivCard>
      ) : (
        <div className={styles.products}>
          <div className={styles.wrapper}>
            {activeTab === navigation[0] && <Market items={sortData()} search={search} />}
            {activeTab === navigation[1] && (
              <Table
                title="Traffic source"
                items={traffic}
              />
            )}
            {activeTab === navigation[2] && (
              <Table title="Viewers" items={viewers} />
            )}
          </div>
        </div>
      )}
    </Card>
  );
};

export default ListAccount;
