import React, { useEffect, useState } from "react";
import styles from "./companies.module.sass";
import cn from "classnames";
import Form from "../../components/Form";
import Market from "./Market";
import { Link } from "react-router-dom";
import axios from "axios";
import DivCard from "../../components/CardDiv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from 'xlsx';
import Dropdown from "../../components/Dropdown";

const intervals = [
  "A",
  "Z",
];

const Companies = () => {
  const navigation = ["Viewers"];

  const [activeTab, setActiveTab] = useState(navigation[0]);
  const [search, setSearch] = useState("");
  const [company, setCompany] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortDirection, setSortDirection] = useState("asc"); // Default to ascending
  const [filter, setFilter] = useState("A"); // Default filter

  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios.get("/sanctum/csrf-cookie");
        const response = await axios.get(`api/GetAllCompanies`);
        if (response.data.status === 200) {
          setCompany(response.data.data);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Fetch data only on component mount

  // Function to toggle sort direction
  const toggleSortDirection = () => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  // Function to handle filter change
  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  // Function to sort the data// Function to sort the data
  const sortData = () => {
    return company.slice().sort((a, b) => {
      const nameA = a.company_info.profile.name.toLowerCase();
      const nameB = b.company_info.profile.name.toLowerCase();
      if (filter === 'A') {
        return sortDirection === "asc" ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
      } else if (filter === 'Z') {
        return sortDirection === "asc" ? nameB.localeCompare(nameA) : nameA.localeCompare(nameB);
      }
      return 0;
    });
  };

  const handleExtract = (event) => {
    event.preventDefault(); // Prevent default action of button click

    const data = sortData().map((c) => [
      c.company_info.profile.name,
      c.company_info.email,
      c.hold === 1 ? "Holded" : "Active",
      c.num_users,
      c.num_cards,
      c.seller_name,
    ]);

    const worksheetData = [
      ["Name", "Email", "Status", "Users", "Cards", "Seller Name"],
      ...data
    ];

    // Create a worksheet from the data
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // Create a workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Companies");

    // Generate and download the Excel file
    XLSX.writeFile(workbook, "companies.xlsx");
  };

  return (
    <DivCard
      className={styles.card}
      title="Companies"
      classTitle={cn("title-purple", styles.title)}
      classCardHead={styles.head}
      head={
        <>
          <Form
            className={styles.form}
            value={search}
            setValue={setSearch}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search..."
            type="text"
            name="search"
            icon="search"
          />

          <Dropdown
            className={`${styles.dropdown} ${styles.newStyle}`}
            classDropdownHead={styles.dropdownHead}
            value={filter}
            setValue={handleFilterChange}
            options={intervals}
            small
          />
          <div className={styles.contacts}>
            <button className={cn("button", styles.button)} onClick={handleExtract}>
              Extract
            </button>
            <Link to="/addcompany" className={cn("button", styles.button)}>
              Add New Company
            </Link>
          </div>
        </>
      }
    >
      <div className={styles.products}>
        <div className={styles.wrapper}>
          {activeTab === navigation[0] && (
            <Market
              items={sortData()}
              loading={loading}
              search={search}
              toggleSortDirection={toggleSortDirection}
            />
          )}
          {/* Render other tabs */}
        </div>
      </div>
    </DivCard>
  );
};

export default Companies;
