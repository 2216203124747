import React from 'react'

function Page404() {
  return (
    <div>
      This Page URL isnot Found!
    </div>
  )
}

export default Page404
