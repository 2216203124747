import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./MyLinks.module.sass";
import TooltipGlodal from "../../../../../components/TooltipGlodal";
import Card from "../../../../../components/Card";
import Icon from "../../../../../components/Icon";
// import { Switch } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
const items = [
  {
    title: "Views",
    icon: "user",
    background: "#A694F040",
  },
  {
    title: "Total clicks",
    icon: "user",
    background: "#3CBEA940",
  },
  {
    title: "Share info",
    icon: "user",
    background: "#FFD88D40",
  },
  {
    title: "Save contacts",
    icon: "user",
    background: "#FF6A5540",
  },
  {
    title: "New following",
    counter: 0,
    icon: "user",
    background: "#B1E5FC40",
  },
];

const MyLinks = ({ className, Links, id }) => {
  const [itemss, setItems] = useState([]);
  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.get(`api/GetUserCounts/${id}`).then((res) => {
        if (res.status === 200) {
          const data = res.data.data;
          const updatedItems = items.map((item) => {
            switch (item.title) {
              case "Views":
                return { ...item, counter: data.users };
              case "Total clicks":
                return { ...item, counter: data.clicks };
              case "Share info":
                return { ...item, counter: data.share };
              case "Save contacts":
                return { ...item, counter: data.save_contact };
              case "New following":
                return { ...item, counter: data.following };
              default:
                return item;
            }
          });
          setItems(updatedItems);
        }
      });
    });
  }, []);

  const [check, setcheck] = useState({});
  const [switchState, setSwitchState] = useState({});
  // useEffect(() => {
  //   axios.get("/sanctum/csrf-cookie").then((response) => {
  //     axios
  //       .get("api/EndPoint")
  //       .then((response) => {
  //         const initialSwitchState = {};
  //         response.data.forEach((link) => {
  //           initialSwitchState[link.id] = link.primary;
  //         });
  //         setSwitchState(initialSwitchState);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching switch state:", error);
  //       });
  //   });
  // }, []); // Empty dependency array to run only once when component mounts

  // const handleSwitchChange = (id, checked, e) => {
  //   const newValue = !checked;
  //   axios
  //     .post(`api/PrimarySwitch/${id}`, { newValue: newValue })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         // Update switch state after successful API call
  //         setSwitchState((prevState) => ({
  //           ...prevState,
  //           [id]: newValue,
  //         }));
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error updating switch state:", error);
  //     });
  // };
  // const handleSwitchChangeHide = (id, checked, e) => {
  //   const newValue = !checked;
  //   axios
  //     .post(`api/HideSwitch/${id}`, { newValue: newValue })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         // Update switch state after successful API call
  //         setcheck(newValue)
  //       }
  //       setcheck(newValue)
  //     })
  //     .catch((error) => {
  //       console.error("Error updating switch state:", error);
  //     });
  // };

  return (
    <>
      <Card className={cn(styles.card, className)} classTitle="title-purple">
        <div className={`row${styles.overview}`}>
          <div className={styles.list}>
            {itemss.map((x, index) => (
              <div
                className={`col-md-4 ${styles.item}`}
                key={index}
                style={{ backgroundColor: x.background }}
              >
                <div className={styles.icon}>
                  <Icon name={x.icon} size="24" />{" "}
                </div>
                <div className={styles.line}>
                  <div className={styles.details}>
                    <div className={styles.category}>
                      {x.title}
                      {/* <Tooltip
                        className={styles.tooltip}
                        title="Small description"
                        icon="info"
                        place="right"
                      /> */}
                    </div>
                    <div className={styles.counter}>{x.counter}</div>
                    {/* <div className={styles.indicator}>
                      <Balance className={styles.balance} value={x.value} />
                      <span>this week</span>
                    </div> */}
                  </div>
                  {/* <Chart className={styles.chart} item={x} /> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>
      <TooltipGlodal />
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>Logo</div>
          <div className={styles.col}>Link Name</div>
          <div className={styles.col}>Clicks</div>
          <div className={styles.col}>URL</div>
          {/* <div className={styles.col}>Primary</div>
          <div className={styles.col}>Hide</div> */}
          <div className={styles.col}>Edit</div>
        </div>
        {Links.map((user) =>
          user.links.map((link) => {
            return (
              <div className={styles.row} key={link.id}>
                <div className={styles.col}>
                  <div className={styles.label}>Type</div>
                  {link.type}
                </div>
                <div className={styles.col}>
                  <div className={styles.label}>Link Name</div>
                  {link.name}
                </div>
                <div className={styles.col}>
                  <div className={styles.label}>Clicks</div>
                  {link.clicks}
                </div>

                <div className={styles.col}>
                  <div className={styles.label}>URL</div>
                  {/* Splitting and mapping each part of the URL */}
                  <Link to={link.url}
                    className={` ${styles.edit}`}
                  >{link.url}</Link>
                </div>

                <div className={`${styles.col} `}>
                  <div className={styles.label}>Edit</div>
                  <Link
                    to={`/company/company-editlinks/${link.id}`}
                    className={` ${styles.edit}`}
                  >
                    Edit
                  </Link>
                </div>
              </div>
            );
          })
        )}
      </div>
    </>
  );
};

export default MyLinks;
