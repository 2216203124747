import React, { useEffect, useState, useRef } from "react";
import cn from "classnames";
import styles from "./Details.module.sass";
import Card from "../../../../components/Card";
import Form from "../../../../components/Form";
import Icon from "../../../../components/Icon";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { ScaleLoader } from "react-spinners";
import { QRCodeCanvas } from "qrcode.react";

const Details = ({ className }) => {
  const getStatusColorClass = (status) => {
    switch (status) {
      case "active":
        return styles.active;
      case "notactive":
        return styles.notactive;
      default:
        return "";
    }
  };

  const { id } = useParams();
  const history = useNavigate();
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [getStatus, setGetStatus] = useState([]);
  const [copying, setCopying] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const qrRef = useRef();

  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then(() => {
      axios.get(`api/GetCoordinatorCards/${id}`).then((res) => {
        if (res.status === 200) {
          setUsers(res.data.sales);
        }
        setLoading(false);
      });
    });
  }, [id]);

  const handleReadytoShipped = (e) => {
    e.preventDefault();
    axios.get("/sanctum/csrf-cookie").then(() => {
      axios.post(`api/ReadyStatus/${id}`).then((res) => {
        if (res.data.status === 200) {
          swal("Success!", "Account has been Ready To Ship.", "success");
          history(`/coor`);
        }
      });
    });
  };

  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then(() => {
      axios.get(`api/getStatusCard/${id}`).then((res) => {
        if (res.data.status === 200) {
          setGetStatus(res.data.data);
        }
        setLoading(false);
      });
    });
  }, [id]);

  // URL copy function
  const copyToClipboard = (text) => {
    setCopying(true);
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert("URL copied");
        setCopying(false);
      })
      .catch((error) => {
        console.error("Failed to copy: ", error);
        setCopying(false);
      });
  };

  // Download QR code function
  const downloadQRCode = (e) => {
    e.preventDefault();
    setDownloading(true);
    const canvas = qrRef.current.querySelector("canvas");
    if (!canvas) throw new Error("<canvas> not found in the DOM");

    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "QR code.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    setDownloading(false);
  };

  return (
    <Card
      className={styles.card}
      title={getStatus.name}
      classTitle={cn("title-purple", styles.title)}
      classCardHead={styles.head}
      head={
        <>
          <Form
            className={styles.form}
            value={search}
            setValue={setSearch}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search"
            type="text"
            name="search"
            icon="search"
          />
          <div className={styles.contacts}>
            <div onClick={handleReadytoShipped} className={cn("button", styles.button)}>
              Ready To Ship
            </div>
          </div>
        </>
      }
    >
      {loading ? (
        <center>
          <ScaleLoader color="#8E59FF" />
        </center>
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.col}>Status</div>
              <div className={styles.col}>User</div>
              <div className={styles.col}>URL</div>
              <div className={styles.col}>QR Code</div>
            </div>
            {users
              .filter((item) => {
                return search.toLowerCase() === ""
                  ? item
                  : item.user === null
                  ? ""
                  : item.user.toLowerCase().includes(search);
              })
              .map((x, index) => (
                <div className={styles.row} key={index}>
                  <div className={cn(styles.col)}>
                    <span className={getStatusColorClass(x.status)}>
                      {x.status}
                    </span>
                  </div>
                  <div className={styles.col}>{x.user}</div>
                  <div className={styles.col}>
                    <button
                      onClick={() =>
                        copyToClipboard(`https://daleelnfc.com/ViewProfile/${x.url}`)
                      }
                      disabled={copying}
                    >
                      <Icon name="copy" size="30" className={styles.svgi} />
                    </button>
                  </div>
                  <div className={styles.col}>
                    <div ref={qrRef} style={{ position: "absolute", top: "-9999px", left: "0px", right: "0px" }}>
                      <QRCodeCanvas
                        level="H"
                        size={300}
                        value={`https://daleelnfc.com/ViewProfile/${x.qr_code}`}
                      />
                    </div>
                    <div className="my-5">
                      <button
                        onClick={downloadQRCode}
                        disabled={downloading}
                      >
                        <Icon name="download" size="30" className={styles.svgi} />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </Card>
  );
};

export default Details;
