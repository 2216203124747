import React from "react";
import styles from "./EditLink.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import TextInput from "../../../components/TextInput";

const EditLink = () => {
  return (
    <Card
      className={styles.card}
      title="Edit New Link"
      classTitle={cn("title-purple", styles.title)}
      classCardHead={styles.head}
    >
      <label className={styles.label}>Type</label>

      <select id="Sector" className={styles.field} name="display-name" required>
        <option value="" label="Facebook"></option>
        <option value="option1" label="Instagram"></option>
        <option value="option2" label="Threads"></option>
        <option value="option2" label="Skype"></option>
        <option value="option2" label="Youtube"></option>
      </select>

      <TextInput
        className={styles.field}
        label="Link Name "
        name="display-name"
        type="text"
        required
      />
      <TextInput
        className={styles.field}
        label="URL "
        name="display-name"
        type="text"
        required
      />
   
    <button className={cn("button", styles.button)}>Edit</button>
      <button className={cn("button", styles.button, styles.custombutton)}>Delete</button>
   
    </Card>
  );
};

export default EditLink;
