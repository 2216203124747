import React, { useEffect, useState } from "react";
import styles from "./Archive.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Form from "../../../components/Form";

import Market from "./Market";
import Table from "./Table";
import Divcard from "../../../components/CardDiv"

// data
import { traffic } from "../../../mocks/traffic";
import { viewers } from "../../../mocks/viewers";
import axios from "axios";

import Dropdown from "../../../components/Dropdown";
// Sorting
const intervals = [
  "A",
  "Z",
];
const ListAccount = () => {
  const navigation = ["Viewers"];
  const [activeTab, setActiveTab] = useState(navigation[0]);
  const [search, setSearch] = useState("");
  const cid = localStorage.getItem('company_id')
  const [archive, setArchive] = useState([]);
  const [loading, setloading] = useState(true);
  const [sortDirection, setSortDirection] = useState("asc"); // Default to ascending
  const [filter, setFilter] = useState("A"); // Default filter

  useEffect(() => {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.get(`api/archiveUsers?cid=${cid}`).then((res) => {
        if (res.data.status === 200) {
          setArchive(res.data.content);
        }
        setloading(false)
      });
    });
  }, []);

  const toggleSortDirection = () => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  // Function to handle filter change
  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };
  const sortData = () => {
    return archive.slice().sort((a, b) => {
      const nameA = a.email?.toLowerCase();
      const nameB = b.email?.toLowerCase();
      if (filter === 'A') {
        return sortDirection === "asc" ? nameA?.localeCompare(nameB) : nameB?.localeCompare(nameA);
      } else if (filter === 'Z') {
        return sortDirection === "asc" ? nameB?.localeCompare(nameA) : nameA?.localeCompare(nameB);
      }
      return 0;
    });
  };

  return (
    <Divcard
      className={styles.card}
      title="User"
      classTitle={cn("title-purple", styles.title)}
      classCardHead={styles.head}
      head={
        <>
          <Form
            className={styles.form}
            value={search}
            setValue={setSearch}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search..."
            type="text"
            name="search"
            icon="search"
          />

          <Dropdown
            className={`${styles.dropdown} ${styles.newStyle}`}
            classDropdownHead={styles.dropdownHead}
            value={filter}
            setValue={handleFilterChange}
            options={intervals}
            small
          />
          {/* <div className={styles.contacts}>
            <Link to="" className={cn("button", styles.button)}>
              Cards
            </Link>
            <Popup
              trigger={
                <button className={cn("button", styles.button)}>Top up</button>
              }
              modal
              nested
            >
              {(close) => (
                <div className="modal">
                  <button className={styles.close} onClick={close}>
                    &times;
                  </button>
                  <div className="content">
                    {" "}
                    <Card
                      className={styles.card}
                      title="Top Up"
                      classTitle={cn("title-purple", styles.title)}
                      classCardHead={styles.head}
                    >
                      <div className={styles.inputrow}>
                        <TextInput
                          className={`${styles.field} ${styles.rowfield}`}
                          label="Number of cards "
                          name="display-name"
                          type="text"
                          required
                        />
                        <TextInput
                          className={`${styles.field} ${styles.rowfield}`}
                          label="Number of User"
                          name="display-name"
                          type="text"
                          required
                        />
                      </div>
                      <div className={`${styles.inputrow} ${styles.rowmargin}`}>
                        <TextInput
                          className={`${styles.field} ${styles.rowfield}`}
                          label="Cost"
                          name="display-name"
                          type="text"
                          required
                        />
                        <TextInput
                          className={`${styles.field} ${styles.rowfield}`}
                          label="Transaction reference"
                          name="display-name"
                          type="text"
                          required
                        />
                      </div>
                      <button
                        className={cn(
                          "button",
                          styles.button,
                          styles.buttonright
                        )}
                      >
                        Top Up
                      </button>
                    </Card>
                  </div>
                </div>
              )}
            </Popup>
          </div> */}
        </>
      }
    >
      <div className={styles.products}>
        <div className={styles.wrapper}>
          {activeTab === navigation[0] && <Market items={sortData()} loading={loading} search={search} />}
          {activeTab === navigation[1] && (
            <Table
              title="Traffic source"
              items={traffic}

            />
          )}
          {activeTab === navigation[2] && (
            <Table title="Viewers" items={viewers} />
          )}
        </div>
      </div>
    </Divcard>
  );
};

export default ListAccount;
