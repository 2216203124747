import React, { useState } from "react";
import styles from "./Connections.module.sass";
import cn from "classnames";
import Card from "../../../../components/Card";
import Form from "../../../../components/Form";
import { Link } from "react-router-dom";
// import Dropdown from "../../../components/Dropdown";
import Market from "./Market";
import Table from "./Table";

// data
import { traffic } from "../../../../mocks/traffic";
import { viewers } from "../../../../mocks/viewers";
import { market } from "../../../../mocks/market";

const indicatorsTraffic = [
  {
    title: "Market",
    color: "#FFBC99",
  },
  {
    title: "Social media",
    color: "#CABDFF",
  },
  {
    title: "Direct",
    color: "#B5E4CA",
  },
  {
    title: "UI8",
    color: "#B1E5FC",
  },
  {
    title: "Others",
    color: "#FFD88D",
  },
];

const indicatorsViewers = [
  {
    title: "Followers",
    color: "#B5E4CA",
  },
  {
    title: "Others",
    color: "#CABDFF",
  },
];

const Connections = ({connections}) => {
  const navigation = ["Viewers"];

  const [activeTab, setActiveTab] = useState(navigation[0]);
  const [search, setSearch] = useState("");

  const handleSubmit = (e) => {
    alert();
  };

  return (
    <Card
      className={styles.card}
     
      classTitle={cn("title-purple", styles.title)}
      classCardHead={styles.head}
      head={
        <>
   
          <div className={styles.contacts}>
            <Link to="" className={cn("button", styles.button)}>
              Extract
            </Link>
          </div>
        </>
      }
    >
      <div className={styles.products}>
        <div className={styles.wrapper}>
          {activeTab === navigation[0] && <Market items={connections} />}
          {activeTab === navigation[1] && (
            <Table
              title="Traffic source"
              items={traffic}
              legend={indicatorsTraffic}
            />
          )}
          {activeTab === navigation[2] && (
            <Table title="Viewers" items={viewers} legend={indicatorsViewers} />
          )}
        </div>
      </div>
    </Card>
  );
};

export default Connections;
