import React, { useState } from "react";
import styles from "./AddCompany.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import DivCard from "../../../components/CardDiv";
import TextInput from "../../../components/TextInput";
import Icon from "../../../components/Icon";
import axios from "axios";
import { useNavigate } from "react-router";
import swal from "sweetalert";

const AddCompany = () => {
  // ProfilePic UPLOAD PIC
  const [Image, setImage] = useState([]);
  const [selectedImage2, setSelectedImage2] = useState(
    "/images/logo-light.png"
  );

  const history = useNavigate();
  const id = localStorage.getItem('id')
  const [loading, setLoading] = useState(true)
  const username = localStorage.getItem('name')
  const [registerInput, setRegister] = useState({
    name: "",
    email: "",
    password: "",
    num_users: "",
    num_cards: "",
    cost: "",
    reference: "",
    error_list: [],
    sucess: "",
  });
  const handleInput = (e) => {
    // e.presist();
    setRegister({ ...registerInput, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    
    e.preventDefault();
    setLoading(false)
    // Create FormData object
    const formData = new FormData();
    formData.append("name", registerInput.name);
    formData.append("email", registerInput.email);
    formData.append("password", registerInput.password);
    formData.append("role", "company");
    formData.append("image", Image.image);
    formData.append("num_users", registerInput.num_users);
    formData.append("num_cards", registerInput.num_cards);
    formData.append("cost", registerInput.cost);
    formData.append("reference", registerInput.reference);
    formData.append("seller_name", username);
    formData.append("seller_id", id);
    formData.append("user_id", id);

    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios
        .post("api/CreateCompany", formData, {
          withCredentials: true, // Include cookies with the request
          headers: {
            "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
            "X-Requested-With": "XMLHttpRequest",
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            setRegister({
              ...registerInput,
              sucess: res.data.message,
              error_list: [],
            });
            setLoading(true)
            swal("Success!",res.data.message,"success")

            history('/companies')

          }else if (res.data.status === 422) {
            const validationErrors = res.data.validation_errors;

            // Format the errors into a readable string
            let errorMessage = '';
            for (const field in validationErrors) {
              if (validationErrors.hasOwnProperty(field)) {
                validationErrors[field].forEach(error => {
                  errorMessage += `${error}\n`;
                });
              }
            }

            // Display the errors using SweetAlert
            swal('Error', errorMessage, 'error');
            setLoading(true);
          }  else {
            setRegister({
              ...registerInput,
              error_list: res.data.validation_errors,
            });
            setLoading(true);
          }
        })
        .catch((error) => {
          console.error("Error registering:", error);
          // Handle registration error
        });
        setLoading(true);
    });
  };

  const handleImage = (event) => {
    setImage({ image: event.target.files[0] });
  };
  const handleFileChange2 = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const newImageUrl = e.target.result;
        setSelectedImage2(newImageUrl);
      };

      reader.readAsDataURL(file);
    }
  };
  const handleMerge = (event) => {
    // Call the first function
    handleFileChange2(event);

    // Call the second function
    handleImage(event);
  };
  return (
    <>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <DivCard
          className={styles.card}
          title="Add New Company"
          classTitle={cn("title-purple", styles.title)}
          classCardHead={styles.head}
        >
          <div className={styles.profile}>
            <div className={styles.avatar}>
              <img src={selectedImage2} alt="Avatar" />
              {/* <span>{registerInput.error_list.image}</span> */}
            </div>
            <div className={styles.file}>
              <input
                type="file"
                onChange={handleMerge}
                name="image"
                accept="image/*"
              />
              <button className={cn("button", styles.button)} type="button">
                <Icon name="add" size="24" />
                <span>Upload New picture</span>
              </button>
            </div>
          </div>
          <TextInput
            className={styles.field}
            label="Company Name "
            name="name"
            type="text"
            required
            onChange={handleInput}
            value={registerInput.name}
          />
          <div className={styles.inputrow}>
            <TextInput
              className={`${styles.field} ${styles.rowfield}`}
              label="Number of cards "
              name="num_cards"
              type="number"
              required
              onChange={handleInput}
              value={registerInput.num_cards}
            />
            <TextInput
              className={`${styles.field} ${styles.rowfield}`}
              label="Number of User"
              name="num_users"
              type="number"
              required
              onChange={handleInput}
              value={registerInput.num_users}
            />
          </div>
          <div className={styles.inputrow}>
            <TextInput
              className={`${styles.field} ${styles.rowfield}`}
              label="Cost (USD) "
              name="cost"
              type="number"
              required
              onChange={handleInput}
              value={registerInput.cost}
            />
            <TextInput
              className={`${styles.field} ${styles.rowfield}`}
              label="Transaction reference"
              name="reference"
              type="text"
              required
              onChange={handleInput}
              value={registerInput.reference}
            />
          </div>
        </DivCard>

        <DivCard
          className={styles.card}
          title="Login"
          classTitle={cn("title-purple", styles.title)}
          classCardHead={styles.head}
        >
          <div className={styles.inputrow}>
            <TextInput
              className={`${styles.field} ${styles.rowfield}`}
              label="Email "
              name="email"
              type="email"
              required
              onChange={handleInput}
              value={registerInput.email}
            />
            <TextInput
              className={`${styles.field} ${styles.rowfield}`}
              label="Password"
              name="password"
              type="password"
              required
              onChange={handleInput}
              value={registerInput.password}
            />
          </div>
          {loading?
          <button type="submit" className={cn("button", styles.button)}>
            Create Company
          </button>
          :
          <button type="submit" disabled className={cn("button", styles.button)}>
            Loading...
          </button>}
        </DivCard>
      </form>
    </>
  );
};

export default AddCompany;
