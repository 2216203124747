import React, { useEffect, useState } from "react";
import styles from "./Sales.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import PayoutHistory from "./PayoutHistory";
import axios from "axios";

const Sales = () => {
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios.get("/sanctum/csrf-cookie"); // Ensure CSRF cookie is set
        const response = await axios.get(`api/GetSales`);
        if (response.data.status === 200) {
          setSales(response.data.data);
        }
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className={styles.section}>
        <PayoutHistory sales={sales} loading={loading} />
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Sales;
